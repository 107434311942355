import React, { useState } from 'react'
import { sendResetPasswordLink } from '../../services/admin'
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
// import {  } from '../../services/admin'
function Reset() {
    const navigate = useNavigate();
    const [email, setEmail] = useState('')
    // const [password, setPassword] = useState('')
    const handleSubmit = async (e) => {
        e.preventDefault();
        let data = {
            email: email
        }
        var res = await sendResetPasswordLink(data);
        
        if (res.data.status == 200) {
            toast.success(res.data.message)
            navigate('/login');
        } else {
            toast.error(res.data.message)
        }
       
    }
    return (

        <div className='login'>
            <div className='login_box container col-4 shadow-lg'>
                <h3 className='text-center text-pink my-4'>Forgot Password </h3>
                <div className=''>
                    <input type="email" required placeholder='email' onChange={(e) => setEmail(e.target.value)} className='form-control' />
                    <br />
                    <button type='sumbit' onClick={handleSubmit} className='login_button btn bg-pink w-100'>Submit</button>
                </div>
            </div>
        </div>
    )
}

export default Reset