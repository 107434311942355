import React from 'react'
import {  NavLink } from 'react-router-dom';
function Menu() {
  return (
    <div><aside className="main-sidebar sidebar-light-pink elevation-2">
      <NavLink to="" className="brand-link">
        <span className="brand-text font-weight-bold ml-5 text-pink"><i>BotshLove</i></span>
      </NavLink>
      <div className="sidebar">
        <div className="form-inline">
        </div>
        <nav className="mt-2">
          <ul className="nav nav-pills nav-sidebar flex-column"  role="menu" data-accordion="false">
            <li className="nav-item menu-open">
              <NavLink to="/" className="nav-link">
                <i className="nav-icon fas fa-tachometer-alt" />
                <p>
                  Dashboard
                </p>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/user/list" className="nav-link">
                <i className="nav-icon fas fa-user" />
                <p>
                  User Management
                  {/* <span className="right badge badge-danger">New</span> */}
                </p>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/subscription/list" className="nav-link">
                <i className="nav-icon fas fa-th" />
                <p>
                  Subscription Management
                  {/* <span className="right badge badge-danger">New</span> */}
                </p>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/query/list" className="nav-link">
                <i className="nav-icon fas fa-question-circle" />
                <p>
                  Queries Management
                  {/* <span className="right badge badge-danger">New</span> */}
                </p>
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink to="/reason/list" className="nav-link">
                <i className="nav-icon fas fa-exclamation-triangle"></i>
                <p>
                  Report Management
                  {/* <span className="right badge badge-danger">New</span> */}
                </p>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/list" className="nav-link">
                <i className="nav-icon fas fa-th" />
                <p>
                  Hobbies Management
                  {/* <span className="right badge badge-danger">New</span> */}
                </p>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/country/list" className="nav-link">
                {/* <i className="nav-icon fas fa-th" /> */}
                <i class="nav-icon fas fa-globe-africa" />
                <p>
                  Country Management
                  {/* <span className="right badge badge-danger">New</span> */}
                </p>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/notification/list" className="nav-link">
                {/* <i className="nav-icon fas fa-th" /> */}
                <i class="nav-icon fas fa-bell"></i>
                <p>
                  Notification Management
                  {/* <span className="right badge badge-danger">New</span> */}
                </p>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/user-reported" className="nav-link">
                {/* <i className="nav-icon fas fa-th" /> */}
                <i class="nav-icon fas fa-question-circle"></i>
                <p>
                  User Report
                  {/* <span className="right badge badge-danger">New</span> */}
                </p>
              </NavLink>
            </li>
            <li className='nav-item  menu-open'>
              {/* <NavLink to="#" className="nav-link">
                <i class="nav-icon fas fa-th"></i>
                <p>Components</p>
                <i class="right fas fa-angle-left"></i>
              </NavLink> */}
              <ul className="nav">
                <li className='nav-item'>
                  <NavLink to="/bodyTypelist" className="nav-link">
                    <i class="far fa-circle nav-icon"></i>
                    <p>Body Type</p>
                  </NavLink>
                </li>
                <li className='nav-item'>
                  <NavLink to="/smokinglist" className="nav-link">
                    <i class="far fa-circle nav-icon"></i>
                    <p>Smoking</p>
                  </NavLink>
                </li>
                <li className='nav-item'>
                  <NavLink to="/relationstatus" className="nav-link">
                    <i class="far fa-circle nav-icon"></i>
                    <p>Current Relationship</p>
                  </NavLink>
                </li>
                <li className='nav-item'>
                  <NavLink to="/qualification" className="nav-link">
                    <i class="far fa-circle nav-icon"></i>
                    <p>Qualification</p>
                  </NavLink>
                </li>      
              </ul>
            </li>  
          </ul>
        </nav>
      </div>
    </aside></div>
  )
}

export default Menu;