import React, { useState, useEffect } from 'react'
import { getQualificationList, addQualification, statusChange, deleteRow } from '../../services/qualification'
import { Modal, Button } from 'react-bootstrap';
import Header from '../Header'
import Menu from '../Menu'
import Pagination from "react-js-pagination";
import { toast } from 'react-toastify';
import Loader from '../shared/loader'
import swal from 'sweetalert';
function QualificationList() {
    const [qualification, setQualification] = useState([]);
    const [newQualifications, setNewQualifications] = useState("")
    const [newQualificationsFr, setNewQualificationsFr] = useState("")
    const [value, setValue] = useState(0);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [loader, setLoader] = useState(true)
    const [updateQualification, setUpdateQualification] = useState({})
    const [activePage, setActivePage] = useState(1)
    const [total, setTotal] = useState(0)
    var count = ((activePage - 1) * 10 + 1);
    // add
    const handleShow = (value) => {
        setValue(value)
        setNewQualifications("")
        console.log(value)
        setShow(true)
    }
    //Edit 
    const handleEditQualification = async (data, value) => {
        console.log("data", data);
        const { qualificationName, qualificationNameFr } = data
        setUpdateQualification(data)
        setNewQualifications(qualificationName)
        setNewQualificationsFr(qualificationNameFr)
        if (data) {
            setValue(value);
            setShow(true)
        }
    }

    useEffect(() => {
        qualificationlist()
    }, [])

    const handleDelete = async (id) => {
        swal({
            title: "Are you sure?",
            icon: "warning",
            buttons: true,
            dangerMode: true
         })
            .then(async (ok) => {
               if (ok) {

                  let data = {
                     id: id
                  }
                  let totalCurrentData = qualification.length;
                  let res = await deleteRow(data);

                  let newActivePage = activePage;
                  if ((totalCurrentData == 1) && (activePage != 1)) {
                     newActivePage = newActivePage - 1
                  }
                  setActivePage(newActivePage);
                  qualificationlist(newActivePage)
                  swal("Good job!", res.data.message, "success");
               }
            });
     }

    const handlePageClick = (page) => {
        setActivePage(page)
        qualificationlist(page)
     }

    const qualificationlist = async (page = activePage) => {
        try {
            setLoader(true)
            let res = await getQualificationList(page);
            // console.log("qqqq", res);
            const qtype = res.data.data.qualificationlist
            setLoader(false)
            setQualification(qtype)
            setTotal(res.data.data.total)
        } catch (error) {
            console.log(error);
        }
    }

    const handleSubmit = async () => {
        console.log("qqq", newQualifications);
        const obj = {
            "key": "1",
            "qualificationName": newQualifications.trim(),
            "qualificationNameFr": newQualificationsFr.trim(),
            "isActive": true

        }
        if (newQualifications == "") {
            toast.error("Input field is required.")
        }
        let { data } = await addQualification(obj);
        if (data.status === 201) {
            toast.success(data.message)
            handleClose()
            qualificationlist()
        } else {
            toast.error(data.message)
        }

    }

    const handleEditSubmit = async () => {
        console.log("Inside Submit", updateQualification);
        let { _id, isActive, qualificationName } = updateQualification
        let data = {
            _id,
            qualificationName: newQualifications.trim(),
            qualificationNameFr: newQualificationsFr.trim(),
            isActive,
            key: 2
        }
        let { data: result } = await addQualification(data)
        if (result.status === 200) {
            toast.success(result.message);
            handleClose()
            qualificationlist()
        } else {
            toast.error(result.message)
        }
    }

    const handleChangeStatus = async (data) => {
        console.log("data", data);
        const { _id, isActive, qualificationName } = data
        const updateObject = {
            "key": "2",
            "_id": _id,
            "qualificationName": qualificationName,
            "isActive": !isActive
        }
        let res = await statusChange({ ...updateObject })
        console.log("res", res);

        if (res.status === 200) {
            const newQualifications = [...qualification]
            console.log("nnnn", newQualifications);
            const index = newQualifications.findIndex((m) => m._id === data._id)
            newQualifications[index].status = updateObject.isActive;
            setQualification(newQualifications)
            qualificationlist()

        }

    }
    return (
        <>
            <div>
                {loader == true ? <Loader /> : null}
            </div>
            <Header />
            <Menu />
            <div className='content-wrapper'>
                <div className='breadcrum'>
                    <span>Qualification List</span>
                </div>
                <div className='container'>
                    <div className='button_wrap'>
                        <button className='btn bg-pink btn-sm' onClick={() => handleShow(1)}>Add Qualification</button>
                    </div>
                </div>
                <div className='card card-body'>
                    <table className='table table-hover'>
                        <thead>
                            <tr>
                                <td scope='col'>S.no</td>
                                <td scope='col'>Qualification En</td>
                                <td scope='col'>Qualification Fr</td>
                                <td scope='col'>Status</td>
                                <td scope='col'>Edit</td>
                            </tr>
                        </thead>
                        <tbody>
                            {qualification && qualification.length > 0 ? qualification.map((obj, i) => (
                                <tr>
                                    <td>{i + 1}</td>
                                    <td>{obj.qualificationName}</td>
                                    <td>{obj.qualificationNameFr}</td>
                                    <td onClick={() => handleChangeStatus(obj)}>
                                        {
                                            obj.isActive ? (
                                                <button className='btn bg-pink'>Active</button>
                                            ) : (
                                                <button className='btn btn-outline-danger'>InActive</button>
                                            )}
                                    </td>
                                    <td><button className='iconBtn' onClick={() => handleEditQualification(obj, 2)}><i class="fas text-pink fa-edit"></i></button></td>
                                    <td><button className='iconBtn' onClick={() => handleDelete(obj._id)}><i class="fas text-pink fa-trash"></i></button></td>

                                </tr>
                            )) : "No data found"}
                        </tbody>
                    </table>
                    <div className="Pagination">
                     <Pagination
                        activePage={activePage}
                        previousLabel={"previous"}
                        nextLabel={"next"}
                        itemsCountPerPage={10}
                        totalItemsCount={total}
                        pageRangeDisplayed={10}
                        onChange={handlePageClick}
                     />
                  </div>
                </div>
                {
                    value == 1 ?
                        < Modal
                            show={show}
                            onHide={handleClose}
                            backdrop="static"
                            keyboard={false}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Add Qualification</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <form>
                                    <input className='input' maxLength={30} placeholder='Enter Qualification (En)' type="text" onChange={e => { setNewQualifications(e.target.value) }} />
                                    <input className='input' maxLength={30} placeholder='Enter Qualification (Fr)' type="text" onChange={e => { setNewQualificationsFr(e.target.value) }} />
                                </form>
                            </Modal.Body>
                            <Modal.Footer>

                                <Button className='btn bg-pink' onClick={handleSubmit}>Submit</Button>
                            </Modal.Footer>
                        </Modal> :
                        < Modal
                            show={show}
                            onHide={handleClose}
                            backdrop="static"
                            keyboard={false}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Edit Qualification</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <form>
                                    <input className='input' maxLength={30} defaultValue={newQualifications} placeholder='Enter Qualification (En)' type="text" onChange={e => { setNewQualifications(e.target.value) }} />
                                    <input className='input' maxLength={30} defaultValue={newQualificationsFr} placeholder='Enter Qualification (Fr)' type="text" onChange={e => { setNewQualificationsFr(e.target.value) }} />
                                </form>
                            </Modal.Body>
                            <Modal.Footer>

                                <Button className='btn bg-pink' onClick={handleEditSubmit}>Submit</Button>
                            </Modal.Footer>
                        </Modal>
                }
            </div>
        </>
    )
}

export default QualificationList