import httpservice from "./httpservice";
import { API } from "../backend";

httpservice.setJwt(getJwt());

export function getJwt(){
    return localStorage.getItem('logintoken');
}

export function notificationsList(data){
    return httpservice.get(`${API}admin/notification/list?page=${data.page}`)
}

export function deleteNotificationById(data){
    return httpservice.get(`${API}admin/notification/delete?id=${data}`)
}