import React, { useState } from "react";
import { LogoImg } from "../images";
import { type } from "@testing-library/user-event/dist/type";
import { getCmsDetails } from "../services/cms";
import SuperTag from "../component/supertech/Supertech";
function TermsCondition() {
  const [termsData, setTermsData] = useState("");
  React.useLayoutEffect(() => {
    const getTermsData = async () => {
      try {
        const { data } = await getCmsDetails((type = "terms"));
        console.log("Terms & Condition data", data);
        if (data.status === 200) {
            setTermsData(data.data.cms.description);
        } else {
        }
      } catch (err) {
        console.log("Error msg" + err);
      }
    };
    getTermsData();
  }, []);
  return (
    <>
      <div className="staticPage">
        <div className="header">
          <div className="container">
            <div className="logo">
              <img src={LogoImg} alt="" />
            </div>
          </div>
        </div>
        <div className="staticPage_content_wrap">
          <div className="container">
            <div className="page_title">
              <h3>Terms & Condition</h3>
            </div>
            <div className="staticPage_content">
              <div className="terms_condition_content spacing">
                <SuperTag>{termsData}</SuperTag>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TermsCondition;
