import React from 'react'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { getSubscriptionList, changeSubscriptionStatus } from '../../services/subsciption'
import Header from '../Header'
import Menu from '../Menu'
import Loader from '../shared/loader'
function SubscriptionList() {
    const [loader, setLoader] = useState(true)
    const [activePage, setActivePage] = useState(1);

    const [getSubscriptionsList, setSubscriptionsList] = useState([]);
    const subsciptionList = async (page = activePage) => {

        var data = {
            page: page
        };

        setLoader(true);

        var res = await getSubscriptionList(data);
        if (res.data.status == 200) {
            setSubscriptionsList(res.data.data.subscriptionPlans);
        }
        setLoader(false);
    }

    const handleChangeStatus = async (data) => {
        var data = {
            id: data
        };

        setLoader(true);
        var res = await changeSubscriptionStatus(data);
        if (res.data.status == 200) {
            subsciptionList(activePage);
        }
        setLoader(false);
    }

    useEffect(() => {
        subsciptionList();
    }, [])

    var count = ((activePage - 1) * 10 + 1);

    return (
        <>
            <div>
                {loader === true ? <Loader /> : null}
            </div>
            <Header />
            <Menu />
            <div className='content-wrapper'>
                <div className="breadcrum">
                    <span>Subscription Management</span>
                </div>
                <div className='card card-body'>
                    <table className='table table-hover'>
                        <thead>
                            <tr>
                                <th scope='col'>S.no</th>
                                <th scope='col'>Name</th>
                                <th scope='col'>Duration</th>
                                <th scope='col'>Benefits</th>
                                <th scope='col'>Amount</th>
                                <th scope='col'>Status</th>
                                <th scope='col'>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {getSubscriptionsList && getSubscriptionsList.length > 0 ? getSubscriptionsList.map((value, i) => (<tr>
                                <td>{count++}</td>
                                <td>{value.plan_name}</td>
                                <td>{value.plan_duration}</td>
                                <td>
                                    <ol>*.{value.like_limit} Likes</ol>
                                    <ol>*.{value.boost_profile_limit} Boost Profile</ol>
                                    <ol>*.See Who likes Your Profile</ol>
                                    <ol>*.{value.super_like_limit} Super Likes In a week</ol>
                                    {value.exchange_message ? (<ol>*.Exchange Message Directly</ol>) : ''}
                                    {value.see_who_likes ? (<ol>*.See Who likes Your Profile</ol>) : ''}
                                </td>
                                <td>{value.plan_amount}</td>
                                <td onClick={() => handleChangeStatus(value._id)}>
                                    {
                                        value.status ? (
                                            <button className='btn bg-pink'>Active</button>
                                        ) : (
                                            <button className='btn btn-outline-danger'>InActive</button>
                                        )}
                                </td>
                                {/* <td>{value.status ? 'Active' : 'Inactive'}</td> */}
                                <td><Link to={`/subscription/edit/${value._id}`}><i class="fas fa-edit"></i></Link></td>
                            </tr>)) : 'No data found'}
                        </tbody>
                    </table>
                </div>

            </div>
        </>
    )
}

export default SubscriptionList