import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify';
import { getHobbiesList, addHobby, statusChange, deleteHobby } from "../../services/hobbies"
import { Modal, Button } from 'react-bootstrap';
import Header from '../Header'
import swal from 'sweetalert';
import Loader from '../shared/loader'
import Menu from '../Menu'
import Pagination from 'react-js-pagination';
function HobbiesList() {
   let patchHobby = {};
   const [show, setShow] = useState(false);
   const handleClose = () => setShow(false);
   const [value, setValue] = useState(0);
   const [hobbies, setHobbies] = useState([]);

   const [newHobby, setHobby] = useState("");
   const [newHobbyFr, setHobbyFr] = useState("");
   const [updateHobby, setUpdateHobby] = useState({})
   const [loader, setLoader] = useState(true)
   const [activePage, setActivePage] = useState(1)
   const [total, setTotal] = useState(0)
   var count = ((activePage - 1) * 10 + 1);
   // add
   const handleShow = (value) => {
      // console.log('vvvvvvvvvvvvvv', value);
      setValue(value);
      console.log(value)
      setShow(true)
   }
   // edit
   const handleEditHobbie = async (data, value) => {
      setUpdateHobby(data)
      const { hobbyName, hobbyNameFr } = data
      setHobby(hobbyName)
      setHobbyFr(hobbyNameFr)
      if (data) {
         setValue(value);
         setShow(true)
      }
   }

   const handleDeleteHobie = async (id) => {
      swal({
         title: "Are you sure?",
         icon: "warning",
         buttons: true,
         dangerMode: true
      })
         .then(async (ok) => {
            if (ok) {
               let data = {
                  id: id
               }
               let totalCurrentHobbies = hobbies.length;
               let res = await deleteHobby(data);
               let newActivePage = activePage;
               console.log("totalCurrentHobbies", totalCurrentHobbies);
               if ((totalCurrentHobbies == 1) && (activePage != 1)) {
                  newActivePage = newActivePage - 1
               }
               setActivePage(newActivePage);
               hobbiesList(newActivePage)
               swal("Good job!", res.data.message, "success");
            }
         });
   }

   useEffect(() => {
      hobbiesList()
   }, [])


   const hobbiesList = async (page = activePage) => {
      try {
         setLoader(true)
         let response = await getHobbiesList(page);
         const { data } = response.data;
         console.log("response", data)
         setLoader(false)
         setHobbies(data.hobbies)
         setTotal(data.total)
      } catch (err) {
         console.log(err)
      }
   }

   const handleSubmit = async () => {
      setValue(1);
      console.log("new hobbies", newHobby);
      const obj = {
         "key": "1",
         "hobbyName": newHobby.trim(),
         "hobbyNameFr": newHobbyFr.trim(),
         "isActive": true
      }
      let { data } = await addHobby(obj);
      if (data.status == 201) {
         toast.success(data.message)
         handleClose()
         hobbiesList()
      } else {
         toast.error(data.message)
      }
   }
   const handleEditSubmit = async () => {
      if (!newHobby) {
         return;
      }
      let { _id, isActive } = updateHobby

      let data = {
         _id,
         hobbyName: newHobby.trim(),
         hobbyNameFr: newHobbyFr.trim(),
         isActive,
         key: 2,
      }
      let { data: result } = await addHobby(data);
      if (result.status === 200) {
         toast.success(result.message)
         handleClose()
         hobbiesList()
      } else {
         toast.error(result.message)
      }
   }

   const handleChangeStatus = async (data) => {
      const { _id, newHobby, isActive } = data
      const updateObject = {
         "key": "2",
         "_id": _id,
         "hobbyName": newHobby,
         "isActive": !isActive
      }
      console.log("aaa", updateObject)
      let response = await statusChange({ ...updateObject })
      if (response.status == 200) {
         const newHobbyList = [...hobbies]
         console.log("hobby list", newHobbyList)
         const index = newHobbyList.findIndex((m) => m._id === data._id)
         newHobbyList[index].status = updateObject.isActive;
         setHobbies(newHobbyList)
         hobbiesList()
      } setLoader(false)
   }

   const handlePageClick = (page) => {
      setActivePage(page)
      hobbiesList(page)
   }
   return (
      <>
         <div>
            {loader == true ? <Loader /> : null}
         </div>
         <Header />
         <Menu />
         <div className='content-wrapper'>

            <div className="breadcrum">
               <span>Hobbies Management</span>
            </div>
            <div className="container">
               <div className='button_wrap'>
                  <button class='btn bg-pink btn-sm' onClick={() => handleShow(1)}>Add hobbies</button>
               </div>
            </div>
            <div className='card card-body'>
               <table className='table table-hover'>
                  <thead>
                     <tr>
                        <th scope="col">S.no</th>
                        <th scope="col">Hobby</th>
                        <th scope="col">Hobby Fr</th>
                        <th scope="col">Status</th>
                        <th scope="col">Edit</th>
                        <th scope="col">Delete</th>
                     </tr>
                  </thead>
                  <tbody>
                     {hobbies && hobbies.length > 0 ? hobbies.map((obj, i) => (<tr>
                        <td scope="row">{count++}</td>
                        <td>{obj.hobbyName}</td>
                        <td>{obj.hobbyNameFr}</td>
                        <td onClick={() => handleChangeStatus(obj)}>
                           {
                              obj.isActive ? (
                                 <button className='btn bg-pink'>Active</button>
                              ) : (
                                 <button className='btn btn-outline-danger'>InActive</button>
                              )}
                        </td>
                        <td><button className='iconBtn' onClick={() => handleEditHobbie(obj, 2)}><i class="fas text-pink fa-edit"></i></button></td>
                        <td><button className='iconBtn' onClick={() => handleDeleteHobie(obj._id)}><i class="fas text-pink fa-trash"></i></button></td>
                     </tr>)) : "No data found"}
                  </tbody>
               </table>
               <div className="Pagination">
                  <Pagination
                     activePage={activePage}
                     previousLabel={"previous"}
                     nextLabel={"next"}
                     itemsCountPerPage={10}
                     totalItemsCount={total}
                     pageRangeDisplayed={10}
                     onChange={handlePageClick}
                  />
               </div>
            </div>
            {
               value == 1 ?
                  <Modal
                     show={show}
                     onHide={handleClose}
                     backdrop="static"
                     keyboard={false}
                  >

                     <Modal.Header closeButton>
                        <Modal.Title>Add Hobbies</Modal.Title>
                     </Modal.Header>
                     <Modal.Body>
                        <form>
                           <input className='input' maxLength={30} placeholder='Enter hobbie' type="text" onChange={e => { setHobby(e.target.value) }} />
                           <input className='input' maxLength={30} placeholder='Enter hobbie (Fr)' type="text" onChange={e => { setHobbyFr(e.target.value) }} />
                        </form>
                     </Modal.Body>
                     <Modal.Footer>

                        <Button className='btn bg-pink' onClick={handleSubmit}>Submit</Button>
                     </Modal.Footer>
                  </Modal> :
                  <Modal
                     show={show}
                     onHide={handleClose}
                     backdrop="static" variant="primary"
                     keyboard={false}
                  >

                     <Modal.Header closeButton>
                        <Modal.Title>Edit Hobbies</Modal.Title>
                     </Modal.Header>
                     <Modal.Body>
                        <form>
                           <input className='input' maxLength={30} defaultValue={newHobby} placeholder='Enter hobbie' type="text" onChange={e => { setHobby(e.target.value) }} />
                           <input className='input' maxLength={30} defaultValue={newHobbyFr} placeholder='Enter hobbie (Fr)' type="text" onChange={e => { setHobbyFr(e.target.value) }} />
                        </form>
                     </Modal.Body>
                     <Modal.Footer>

                        <Button className='btn bg-pink' onClick={handleEditSubmit}>Submit</Button>
                     </Modal.Footer>
                  </Modal>

            }

         </div>

      </>
   )
}


export default HobbiesList