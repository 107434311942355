import React from 'react'
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
function Header() {

  const user = localStorage.getItem('logintoken');
  const navigate = useNavigate();
  // console.log('aaaaaa',user); 
  function logout() {
    swal({
      title: "Are you sure?",
      icon: "warning",
      buttons: true,
      dangerMode: true
    })
      .then(async (ok) => {
        if (ok) {
          localStorage.removeItem('logintoken');
          navigate('/login')
        }
      });
  }
  return (
    <div>
      <nav className="main-header navbar navbar-expand navbar-white navbar-light">
        <ul className="navbar-nav">
          <li className="nav-item">
            <a className="nav-link" data-widget="pushmenu" href="!#" role="button"><i className="fas fa-bars" /></a>
          </li>
        </ul>
        <ul className='navbar-nav ml-auto'>
          <li class="nav-item">
            <a class="nav-link" role="button">
              {/* <a class="nav-link" data-widget="fullscreen"  role="button"> */}
              <button className='btn btn-danger btn-sm' onClick={logout}>Logout</button>
            </a>
          </li>
        </ul>
      </nav>
    </div>

  )
}


export default Header