import React, { useState, useEffect } from 'react'
import Header from '../Header'
import Menu from '../Menu'
import { NavLink } from 'react-router-dom';
import Loader from '../shared/loader'
import { userQueriesList } from '../../services/queries';
import moment from 'moment';
import { Button, Pagination, Modal } from 'react-bootstrap';
function SolvedQueryList() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [loader, setLoader] = useState(true)
  const [activePage, setActivePage] = useState(1);
  const [getUserQueriesList, setUserQueriesList] = useState([]);
  const [getTotalCount, setTotalCount] = useState(0);

  const queryList = async (page = activePage, search = getHandleSearch, gender = getHandleGenderSelect, date = getHandleDateChange, toDate = getHandleToDateChange) => {

    var data = {
      page: page,
      gender: gender,
      date: date,
      toDate: toDate,
      search: search,
    };

    setLoader(true);

    var res = await userQueriesList(data, 1);
    if (res.data.status == 200) {
      setUserQueriesList(res.data.data.queries);
      setTotalCount(res.data.data.total);
    }

    setLoader(false);
  }

  const handlePageClick = (page) => {
    setActivePage(page)
    queryList(page, getHandleSearch, getHandleGenderSelect, getHandleDateChange,getHandleToDateChange)
  }

  const [getMessage, setMessage] = useState('');
  const openDescriptionModel = (value) => {
    setMessage(value);
    setShow(true)
  }

  const [getHandleSearch, setHandleSearch] = useState('');
  const handleSearch = (e) => {
    setHandleSearch(e.target.value);
    queryList(activePage, e.target.value, getHandleGenderSelect, getHandleDateChange,getHandleToDateChange)
  }

  const [getHandleGenderSelect, setHandleGenderSelect] = useState('');
  const handleGenderSelect = (e) => {
    setHandleGenderSelect(e.target.value);
    queryList(activePage, getHandleSearch, e.target.value, getHandleDateChange,getHandleToDateChange)
  }

  const [getHandleDateChange, setHandleDateChange] = useState('');
  const handleDateChange = (e) => {
    console.log(e.target.value);
    setHandleDateChange(e.target.value);
    queryList(activePage, getHandleSearch, getHandleGenderSelect, e.target.value,getHandleToDateChange)
  }

  const [getHandleToDateChange, setToHandleDateChange] = useState('');
  const HandleToDateChange = (e) => {
    setToHandleDateChange(e.target.value);
    queryList(activePage, getHandleSearch, getHandleGenderSelect, getHandleDateChange, e.target.value)
  }

  useEffect(() => {
    queryList();
  }, [])

  var count = ((activePage - 1) * 10 + 1);
  return (
    <><div>
      {loader === true ? <Loader /> : null}
    </div>
      <Header />
      <Menu />
      <div className='content-wrapper'>
        <div className="breadcrum">
          <span>Queries Management</span>
        </div>
        <div>
          <NavLink to="/query/list"><button className='ReBtn  btn btn-primary btn-sm'>New Query</button></NavLink>
        </div>
        <div className="search-container">
          {/* <form action="/action_page.php"> */}
            <input type="text" onChange={(e) => handleSearch(e)} placeholder="Search.." className="searchBox input" />
            <div class='gender'>
              <select className="gender input btn btn-info" onChange={(e) => handleGenderSelect(e)}>
                <option value="">Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>
            <div className='date'>
              <input className='datepick  input' onChange={(e) => handleDateChange(e)} type="date" />
            </div>
            <div className='date'>
              <input className='datepick  input' onChange={(e) => HandleToDateChange(e)} type="date" />
            </div>
          {/* </form> */}
        </div>
        <div>
          <div className='card card-body'>
            <table className='table table-hover'>
              <thead>
                <tr>
                  <th scope="col">S.no</th>
                  <th scope="col">Name</th>
                  <th scope="col">Gender</th>
                  <th scope="col">Email</th>
                  <th scope="col">Mobile Number</th>
                  <th scope="col">Created On</th>
                  <th scope="col">Description</th>
                </tr>
              </thead>
              <tbody>
                {getUserQueriesList && getUserQueriesList.length > 0 ?
                  getUserQueriesList.map((value, i) => (<tr>
                    <td>{count++}</td>
                    <td>{value.name}</td>
                    <td>{value.gender[0]}</td>
                    <td>{value.email}</td>
                    <td>{value.mobile_number}</td>
                    <td>{moment(value.createdAt).format('YYYY/M/D')}</td>
                    <td><Button onClick={() => openDescriptionModel(value.message)}><i class="fas fa-eye"></i></Button></td>
                  </tr>)) : 'No data found'
                }
              </tbody>
            </table>
            <div className="Pagination">
              <Pagination
                activePage={activePage}
                previousLabel={"previous"}
                nextLabel={"next"}
                itemsCountPerPage={10}
                totalItemsCount={getTotalCount}
                pageRangeDisplayed={10}
                onChange={handlePageClick}
              />
            </div>
            <Modal
              show={show}
              onHide={handleClose}
              backdrop="static" variant="primary"
              keyboard={false}
            >

              <Modal.Header closeButton>
                <Modal.Title>Query</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form>
                  {getMessage}
                </form>
              </Modal.Body>
              <Modal.Footer>

                {/* <Button className='btn bg-pink' >Submit</Button> */}
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </>
  )
}

export default SolvedQueryList