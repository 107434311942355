import React, { useState, useEffect } from 'react'
import { getSmokeList, statusChange, addSmoke, deleteRow } from '../../services/smoking'
import { Modal, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Loader from '../shared/loader'
import Pagination from "react-js-pagination";
import Header from '../Header'
import Menu from '../Menu'
import swal from 'sweetalert';
function SmokingList() {
    const [smoke, setSmoke] = useState([]);
    const [newSmoke, setSmoking] = useState("")
    const [newSmokeFr, setSmokingFr] = useState("")
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [value, setValue] = useState(0);
    const [updateSmoke, setUpdateSmoke] = useState({})
    const [loader, setLoader] = useState(true)
    const [activePage, setActivePage] = useState(1)
    const [total, setTotal] = useState(0)
    var count = ((activePage - 1) * 10 + 1);

    // add
    const handleShow = (value) => {
        // console.log('vvvvvvvvvvvvvv', value);
        setValue(value)
        setSmoking("");
        console.log(value)
        setShow(true)
    }

    // edit
    const handleEditSmoke = async (data, value) => {
        const { smokingType, smokingTypeFr } = data
        setUpdateSmoke(data)
        setSmoking(smokingType)
        setSmokingFr(smokingTypeFr)
        if (data) {
            setValue(value);
            setShow(true)
        }
    }

    useEffect(() => {
        smoking()
    }, [])

    const handlePageClick = (page) => {
        setActivePage(page)
        smoking(page)
     }
    const smoking = async (page = activePage) => {
        try {
            setLoader(true)
            let res = await getSmokeList(page);
            // console.log("ssss", res);
            const stype = res.data.data.smokingList
            setLoader(false)
            setSmoke(stype)
            setTotal(res.data.data.total)
        } catch (error) {
            console.log(error);
        }
    }

    const handleChangeStatus = async (data) => {
        console.log('data', data)
        const { _id, smokingType, isActive } = data
        const updateObject = {
            "key": "2",
            "smokingType": smokingType,
            "isActive": !isActive,
            "_id": _id
        }
        let res = await statusChange({ ...updateObject })
        console.log("res", res);

        if (res.status === 200) {

            const newSmokingList = [...smoke]
            console.log("sss", newSmokingList);
            const index = newSmokingList.findIndex((m) => m.id === data.id)
            newSmokingList[index].status = updateObject.isActive;
            setSmoke(newSmokingList)
            smoking()
        }
    }

    const handleSubmit = async () => {
        // console.log("sooo", newSmoke);
        const obj = {
            "key": "1",
            "smokingType": newSmoke.trim(),
            "smokingTypeFr": newSmokeFr.trim(),
            "isActive": true
        }

        if (newSmoke == "") {
            toast.error("Input field is required.")
        }
        let { data } = await addSmoke(obj);
        if (data.status === 201) {
            toast.success(data.message)
            handleClose()
            smoking()
        } else {
            toast.error(data.message)
        }
    }

    const handleEditSubmit = async () => {
        console.log("inside submit", updateSmoke);
        let { _id, isActive } = updateSmoke
        let data = {
            _id,
            smokingType: newSmoke.trim(),
            smokingTypeFr: newSmokeFr.trim(),
            isActive,
            key: 2,
        }
        let { data: result } = await addSmoke(data);
        if (result.status === 200) {
            toast.success(result.message);
            handleClose()
            smoking()
        } else {
            toast.error(result.message);
        }
    }
    const handleDelete = async (id) => {
        swal({
            title: "Are you sure?",
            icon: "warning",
            buttons: true,
            dangerMode: true
         })
            .then(async (ok) => {
               if (ok) {

                  let data = {
                     id: id
                  }
                  let totalCurrentData = smoke.length;
                  let res = await deleteRow(data);

                  let newActivePage = activePage;
                  if ((totalCurrentData == 1) && (activePage != 1)) {
                     newActivePage = newActivePage - 1
                  }
                  setActivePage(newActivePage);
                  smoking(newActivePage)
                  swal("Good job!", res.data.message, "success");
               }
            });
     }
    return (
        <>
            <div>
                {loader == true ? <Loader /> : null}
            </div>
            <Header />
            <Menu />
            <div className='content-wrapper'>
                <div className='breadcrum'>
                    <span>Smoking List</span>
                </div>
                <div className='container'>
                    <div className='button_wrap'>
                        <button className='btn bg-pink btn-sm' onClick={() => handleShow(1)}>Add Smoke</button>
                    </div>
                </div>
                <div className='card card-body'>
                    <table className='table table-hover'>
                        <thead>
                            <tr>
                                <th>S.no</th>
                                <th>Smoking (En)</th>
                                <th>Smoking (Fr)</th>
                                <th>Status</th>
                                <th>Edit</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {smoke && smoke.length > 0 ? smoke.map((obj, i) => (
                                <tr>
                                    <td scope='row'>{i + 1}</td>
                                    <td>{obj.smokingType}</td>
                                    <td>{obj.smokingTypeFr}</td>
                                    <td onClick={() => handleChangeStatus(obj)}>
                                        {
                                            obj.isActive ? (
                                                <button className='btn bg-pink'>Active</button>
                                            ) : (
                                                <button className='btn btn-outline-danger'>InActive</button>
                                            )}
                                    </td>
                                    <td><button className='iconBtn' onClick={() => handleEditSmoke(obj, 2)}><i class="fas text-pink fa-edit"></i></button></td>
                                    <td><button className='iconBtn' onClick={() => handleDelete(obj._id)}><i class="fas text-pink fa-trash"></i></button></td>
                                </tr>

                            )) : "No data found"}

                        </tbody>
                    </table>
                    <div className="Pagination">
                     <Pagination
                        activePage={activePage}
                        previousLabel={"previous"}
                        nextLabel={"next"}
                        itemsCountPerPage={10}
                        totalItemsCount={total}
                        pageRangeDisplayed={10}
                        onChange={handlePageClick}
                     />
                  </div>
                </div>
                {
                    value == 1 ?
                        < Modal
                            show={show}
                            onHide={handleClose}
                            backdrop="static"
                            keyboard={false}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Add Smoking</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <form>
                                    <input className='input' maxLength={30} placeholder='Smoking type (En)' type="text" onChange={e => { setSmoking(e.target.value) }} />
                                    <input className='input' maxLength={30} placeholder='Smoking type (Fr)' type="text" onChange={e => { setSmokingFr(e.target.value) }} />
                                </form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button className='btn bg-pink' onClick={handleSubmit}>Submit</Button>
                            </Modal.Footer>
                        </Modal> :
                        < Modal
                            show={show}
                            onHide={handleClose}
                            backdrop="static"
                            keyboard={false}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Edit Smoking</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <form>
                                    <input className='input' maxLength={30} defaultValue={newSmoke} placeholder='Smoking type (En)' type="text" onChange={e => { setSmoking(e.target.value) }} />
                                    <input className='input' maxLength={30} defaultValue={newSmokeFr} placeholder='Smoking type (Fr)' type="text" onChange={e => { setSmokingFr(e.target.value) }} />
                                </form>
                            </Modal.Body>
                            <Modal.Footer>

                                <Button className='btn bg-pink' onClick={handleEditSubmit}>Submit</Button>
                                
                            </Modal.Footer>
                        </Modal>
                }
            </div>
        </>
    )
}

export default SmokingList