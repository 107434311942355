import React, { useEffect, useState } from 'react'
import { Modal, Button } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import { getReasonList, statusChange, addReason, deleteReason } from '../../services/reason'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../shared/loader'
import Header from '../Header'
import Menu from '../Menu'
import swal from 'sweetalert';
toast.configure()
function ReasonList() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [value, setValue] = useState(0);
    const handleShow = () => setShow(true);
    const [loader, setLoader] = useState(true)
    const [reason, setReason] = useState([]);
    const [updateReason, setUpdateReason] = useState({});
    const [getTotalCount, setTotalCount] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [newReason, setReasons] = useState("")
    const [newReasonFr, setReasonsFr] = useState("")
    var count = ((activePage - 1) * 10 + 1);
    const notify = () => {
        toast("Reason Added Successfully")
    }

    const handlePageClick = (page) => {
        setActivePage(page)
        reasonList(page);
    }

    const handleEditReason = async (e, data, value) => {
        setUpdateReason(data)
        const { reportName, reportNameFr } = data
        setReasons(reportName);
        setReasonsFr(reportNameFr);
        if (data) {
            setValue(value);
            setShow(true)
        }
    }

    const handleAddReason = async () => {
        setValue(1);
        setShow(true)
    }

    useEffect(() => {
        reasonList()
    }, [])
    const reasonList = async (page = activePage) => {
        try {
            setLoader(true)
            let data = {
                page: page
            }
            let res = await getReasonList(data);
            setLoader(false)
            const reports = res.data.data.reports;
            const count = res.data.data.total;
            setReason(reports)
            setTotalCount(count)
        } catch (err) {
            console.log(err);
        }
    }

    const handleChangeStatus = async (data) => {
        console.log("data", data)
        const { _id, reportName, isActive } = data
        const updateObject = {
            "key": "2",
            "_id": _id,
            "reportName": reportName,
            "isActive": !isActive
        }
        console.log("hehe", updateObject)
        let response = await statusChange({ ...updateObject })
        console.log("ressponse", response)

        if (response.status == 200) {
            const newReasonList = [...reason]
            // console.log("aaa",newReasonList);
            const index = newReasonList.findIndex((m) => m._id === data._id)
            newReasonList[index].status = updateObject.isActive;
            setReason(newReasonList)
            reasonList()
        }
    }

    const handleDeleteReason = async (e, id) => {
        swal({
            title: "Are you sure?",
            icon: "warning",
            buttons: true,
            dangerMode: true
        })
            .then(async (ok) => {
                if (ok) {
                    let res = await deleteReason({ id: id });
                    if (res.data.status == 200) {
                        swal("Good job!", res.data.message, "success");
                        reasonList()
                    }
                }
            });
    }

    const handleSubmit = async () => {
        console.log("lalala", newReason)
        const obj = {
            "key": "1",
            "reportName": newReason.trim(),
            "reportNameFr": newReasonFr.trim(),
            "isActive": true
        }
        let { data } = await addReason(obj);
        // notify()
        if (data.status == 201) {
            toast.success(data.message)
            handleClose(handleSubmit)
            reasonList()
        } else {
            toast.error(data.message)
        }

    }

    const handleEditSubmit = async () => {
        if (!newReason) {
           return;
        }
        let { _id, isActive } = updateReason
  
        let data = {
           _id,
           reportName: newReason.trim(),
           reportNameFr: newReasonFr.trim(),
           isActive,
           key: 2,
        }
        let { data: result } = await addReason(data);
        if (result.status === 200) {
           toast.success(result.message)
           handleClose()
           reasonList()
        } else {
            toast.error(result.message)
        }
     }
    return (
        <>
            <div>
                {loader == true ? <Loader /> : null}
            </div>
            <Header />
            <Menu />
            <div className='content-wrapper'>
                <div className="breadcrum">
                    <span>Reason Management</span>
                </div>
                <div className='container'>
                    <div className='button_wrap'>
                        <button className='btn bg-pink btn-sm' onClick={handleAddReason}>Add Reason</button>
                    </div>
                </div>
                <div className='card card-body'>
                    <table className='table table-hover'>
                        <thead>
                            <tr>
                                <th scope='col'>S.no</th>
                                <th scope='col'>Reason</th>
                                <th scope='col'>Reason Fr</th>
                                <th scope='col'>Status</th>
                                <th scope='col'>Edit</th>
                                <th scope='col'>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {reason && reason.length > 0 ? reason.map((obj, i) => (<tr>
                                <td scope="row">{count++}</td>
                                <td>{obj.reportName}</td>
                                <td>{obj.reportNameFr}</td>
                                <td onClick={() => handleChangeStatus(obj)}>
                                    {
                                        obj.isActive ? (
                                            <button className='btn bg-pink'>Active</button>
                                        ) : (
                                            <button className='btn btn-outline-danger'>InActive</button>
                                        )}
                                </td>
                                <td><button className='iconBtn' onClick={(e) => handleEditReason(e, obj, 2)}><i class="fas text-pink fa-edit"></i></button></td>
                                <td><button className='iconBtn' onClick={(e) => handleDeleteReason(e, obj._id)}><i class="fas text-pink fa-trash"></i></button></td>
                            </tr>)) : "No data found"}
                        </tbody>
                    </table>
                    <div className="Pagination">
                        <Pagination
                            activePage={activePage}
                            previousLabel={"previous"}
                            nextLabel={"next"}
                            itemsCountPerPage={10}
                            totalItemsCount={getTotalCount}
                            pageRangeDisplayed={10}
                            onChange={handlePageClick}
                        />
                    </div>
                </div>
                {
                    value == 1 ?
                        <Modal
                            show={show}
                            onHide={handleClose}
                            backdrop="static"
                            keyboard={false}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Add Reason</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <form>
                                    <input className='input' type="text" placeholder='Enter reason name' onChange={e => { setReasons(e.target.value) }} />
                                    <input className='input' type="text" placeholder='Enter reason name (Fr)' onChange={e => { setReasonsFr(e.target.value) }} />
                                </form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button className="btn bg-pink" onClick={() => {
                                    handleSubmit()

                                }}>Submit</Button>
                            </Modal.Footer>
                        </Modal> 
                        :
                        <Modal
                            show={show}
                            onHide={handleClose}
                            backdrop="static"
                            keyboard={false}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Edit Reason</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <form>
                                    <input className='input' type="text" value={newReason} placeholder='Enter reason name' onChange={e => { setReasons(e.target.value) }} />
                                    <input className='input' type="text" value={newReasonFr} placeholder='Enter reason name (Fr)' onChange={e => { setReasonsFr(e.target.value) }} />
                                </form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button className="btn bg-pink" onClick={() => {
                                    handleEditSubmit()

                                }}>Submit</Button>
                            </Modal.Footer>
                        </Modal>
                }
            </div>
        </>
    )
}

export default ReasonList