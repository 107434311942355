import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import { getBodyList, statusChange, addBody, deleteRow } from "../../services/body"
import { Modal, Button } from 'react-bootstrap';
import Loader from '../shared/loader'
import Header from '../Header'
import Pagination from "react-js-pagination";
import Menu from '../Menu'
function BodyList() {
    const [body, setBody] = useState([]);
    const [newBody, setBodies] = useState("");
    const [newBodyFr, setBodiesFr] = useState("");
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [value, setValue] = useState(0);
    const [updateBody, setUpdateBody] = useState({})
    const [loader, setLoader] = useState(true)
    const [activePage, setActivePage] = useState(1)
    const [total, setTotal] = useState(0)
    var count = ((activePage - 1) * 10 + 1);

    // add
    const handleShow = (value) => {
        // console.log('vvvvvvvvvvvvvv', value);
        setValue(value)
        setBodies("");
        console.log(value)
        setShow(true)
    }
    // edit
    const handleEditBody = async (data, value) => {
        const { bodyType } = data
        console.log("data", data)
        setUpdateBody(data)
        setBodies(bodyType)
        setBodiesFr(data.bodyTypeFr)
        if (data) {
            setValue(value);
            setShow(true)
        }
    }

    const handleDelete = async (id) => {      

        swal({
            title: "Are you sure?",
            icon: "warning",
            buttons: true,
            dangerMode: true
         })
            .then(async (ok) => {
               if (ok) {

                  let data = {
                     id: id
                  }
                  let totalCurrentBodies = body.length;
                  let res = await deleteRow(data);

                  let newActivePage = activePage;
                  if ((totalCurrentBodies == 1) && (activePage != 1)) {
                     newActivePage = newActivePage - 1
                  }
                  setActivePage(newActivePage);
                  bodylist(newActivePage)
                  swal("Good job!", res.data.message, "success");
               }
            });
     }


    useEffect(() => {
        bodylist()
    }, [])
    const handlePageClick = (page) => {
        setActivePage(page)
        bodylist(page)
     }
    const bodylist = async (page= activePage) => {
        try {
            setLoader(true)
            let res = await getBodyList(page);
            const btype = res.data.data.bodytypelist;
            setLoader(false)
            setBody(btype)
            setTotal(res.data.data.total)
        } catch (error) {
            console.log(error);
        }
    }
    const handleChangeStatus = async (data) => {
        console.log("data", data)
        const { _id, bodyType, isActive } = data
        const updateObject = {
            "key": "2",
            "_id": _id,
            "bodyType": bodyType,
            "isActive": !isActive
        }
        // console.log("body", updateObject);
        let res = await statusChange({ ...updateObject })
        console.log("ressponse", res)

        if (res.status === 200) {
            const newBodyList = [...body]
            console.log("aaa", newBodyList);
            const index = newBodyList.findIndex((m) => m.id === data.id)
            newBodyList[index].status = updateObject.isActive;
            setBody(newBodyList)
            bodylist()
        }
    }
    const handleSubmit = async () => {
        const obj = {
            "key": "1",
            "bodyType": newBody.trim(),
            "bodyTypeFr": newBodyFr.trim(),
            "isActive": true
        }
        if (newBody == "") {
            toast.error("Input Field is required.")
        } else {
            let { data } = await addBody(obj);
            if (data.status === 201) {
                toast.success(data.message)
                handleClose()
                bodylist()
            } else {
                toast.error(data.message);
            }
        }
    }
    const handleEditSubmit = async () => {
        console.log("Inside  submit", updateBody);
        let { _id, isActive } = updateBody
        let data = {
            _id,
            bodyType: newBody.trim(),
            bodyTypeFr: newBodyFr.trim(),
            isActive,
            key: 2,
        }
        let { data: result } = await addBody(data);
        if (result.status === 200) {
            toast.success(result.message);
            handleClose()
            bodylist()
        } else {
            toast.error(result.message);
        }

    }
    return (
        <>
            <div>
                {loader == true ? <Loader /> : null}
            </div>
            <Header />
            <Menu />
            <div className='content-wrapper'>
                <div className="breadcrum">
                    <span>Body Type Listing</span>
                </div>
                <div className='container'>
                    <div className='button_wrap'>
                        <button className='btn bg-pink btn-sm' onClick={() => handleShow(1)}>Add Body Type</button>
                    </div>
                </div>
                <div className='card card-body'>
                    <table className='table table-hover'>
                        <thead>
                            <tr>
                                <th scope='col'>S.no</th>
                                <th scope='col'>Body Type (En)</th>
                                <th scope='col'>Body Type (Fr)</th>
                                <th scope='col'>Status</th>
                                <th scope='col'>Edit</th>
                                <th scope='col'>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {body && body.length > 0 ? body.map((obj, i) => (
                                <tr>
                                    <td scope='row'>{i + 1}</td>
                                    <td>{obj.bodyType}</td>
                                    <td>{obj.bodyTypeFr}</td>
                                    <td onClick={() => handleChangeStatus(obj)}>
                                        {
                                            obj.isActive ? (
                                                <button className='btn bg-pink'>Active</button>
                                            ) : (
                                                <button className='btn  btn-outline-danger'>InActive</button>
                                            )}
                                    </td>
                                    <td><button className='iconBtn' onClick={() => handleEditBody(obj, 2)}><i class="fas text-pink fa-edit"></i></button></td>
                                    <td><button className='iconBtn' onClick={() => handleDelete(obj._id)}><i class="fas text-pink fa-trash"></i></button></td>
                                </tr>
                            )): "No data found"}

                        </tbody>
                    </table>
                    <div className="Pagination">
                     <Pagination
                        activePage={activePage}
                        previousLabel={"previous"}
                        nextLabel={"next"}
                        itemsCountPerPage={10}
                        totalItemsCount={total}
                        pageRangeDisplayed={10}
                        onChange={handlePageClick}
                     />
                  </div>
                </div>
                {
                    value == 1 ?
                        < Modal
                            show={show}
                            onHide={handleClose}
                            backdrop="static"
                            keyboard={false}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Add Body Type</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <form>
                                    <input className='input' maxLength={30} placeholder='Enter Body Type (En)' type="text" onChange={e => { setBodies(e.target.value) }} />
                                    <input className='input' maxLength={30} placeholder='Enter Body Type (Fr)' type="text" onChange={e => { setBodiesFr(e.target.value) }} />
                                </form>
                            </Modal.Body>
                            <Modal.Footer>

                                <Button className='btn bg-pink' onClick={handleSubmit}>Submit</Button>
                            </Modal.Footer>
                        </Modal> :
                        < Modal
                            show={show}
                            onHide={handleClose}
                            backdrop="static"
                            keyboard={false}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Edit Body Type</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <form>
                                    <input className='input' maxLength={30} defaultValue={newBody} placeholder='Enter Body Type (En)' type="text" onChange={e => { setBodies(e.target.value) }} />
                                    <input className='input' maxLength={30} defaultValue={newBodyFr} placeholder='Enter Body Type (Fr)' type="text" onChange={e => { setBodiesFr(e.target.value) }} />
                                </form>
                            </Modal.Body>
                            <Modal.Footer>

                                <Button className='btn bg-pink' onClick={handleEditSubmit}>Submit</Button>
                            </Modal.Footer>
                        </Modal>
                }
            </div>
        </>
    )
}

export default BodyList