import httpservice from "./httpservice";
import { API } from "../backend";

export function getHobbiesList(page){
      return httpservice.get(`${API}admin/hobby?page=${page}`)
}

export function addHobby(data){
      return httpservice.post(`${API}admin/hobby`,(data))
}

export function statusChange(data){
      return httpservice.post(`${API}admin/hobby`,(data))
}

export function deleteHobby(data){
      return httpservice.get(`${API}admin/deleteHobby?id=${data.id}`)
}