import httpservice from "./httpservice";
import { API } from "../backend";

httpservice.setJwt(getJwt());

export function getJwt(){
    return localStorage.getItem('logintoken');
}

export function getUserList(data){
    console.log("localStorage.getItem('logintoken')", localStorage.getItem('logintoken'))
    return httpservice.get(`${API}admin/userLists?page=${data.page || ''}&limit=${data.limit || ''}&search=${data.search || ''}&gender=${data.gender}&from_date=${data.from_date}&to_date=${data.to_date}`)
}

export function changeUserStatus(data){
    return httpservice.get(`${API}admin/userStatus?id=${data.id}`)
}

export function getUserDetails(id){
    return httpservice.get(`${API}admin/userDetails/${id}`)
}