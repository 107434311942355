import React from "react";
import { LogoImg } from "../images";
import { Link } from "react-router-dom";
import { useState } from "react";
import { getCmsDetails } from "../services/cms";
import SuperTag from "../component/supertech/Supertech";
function PrivacyPolicy() {
  const [privacyData, setPrivacyData] = useState("");

  React.useLayoutEffect(() => {
    const getPrivacyData = async () => {
      try {
        const { data } = await getCmsDetails();
        console.log(data);
        // console.log("new data" + data.data.cms.description);
        if (data.status === 200) {
          setPrivacyData(data.data.cms.description);
        } else {
          //   toast.error(res.message);
        }
      } catch (err) {
        // toast.error(err?.response?.data?.message || err.message);
        console.log("Error msg" + err);
      }
    };

    // calls all the master functions
    getPrivacyData();

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="staticPage">
        <div className="header">
          <div className="container">
            <div className="logo">
              <img src={LogoImg} alt="" />
            </div>
          </div>
        </div>
        <div className="staticPage_content_wrap">
          <div className="container">
            <div className="page_title">
              <h3>Privacy Policy</h3>
            </div>
            <div className="staticPage_content">
              <div className="terms_condition_content spacing">
                <SuperTag>{privacyData}</SuperTag>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PrivacyPolicy;
