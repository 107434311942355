import httpservice from "./httpservice";
import { API } from "../backend";

httpservice.setJwt(getJwt());

export function getJwt(){
    return localStorage.getItem('logintoken');
}

export function getSubscriptionList(data){
    return httpservice.get(`${API}admin/subscription/list?page=${data.page}`)
}

export function changeSubscriptionStatus(data){
    return httpservice.get(`${API}admin/subscription/status?id=${data.id}`)
}

export function getSubscriptionDetail(data){
    return httpservice.get(`${API}admin/subscription/detail?id=${data.id}`)
}