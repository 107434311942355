import httpservice from "./httpservice";
import {
    API
} from "../backend";

export function getBodyList(page) {
    return httpservice.get(`${API}admin/body?page=${page}`)
}

export function statusChange(data){
    return httpservice.post(`${API}admin/body`,(data))
}

export function addBody(data){
    return httpservice.post(`${API}admin/body`,(data))
}

export function deleteRow(data){
    return httpservice.get(`${API}admin/deleteBody?id=${data.id}`)
}
