import httpservice from "./httpservice";
// import httpservice, { setNewJWT } from "./httpservice";
import { API } from "../backend";

export function loginAPi(data){
    return httpservice.post(`${API}admin/login`,(data))
    // return httpservice.post(`${API}admin/login`,(data),setNewJWT())
}

export function sendResetPasswordLink(data){
    return httpservice.post(`${API}admin/send-reset-password-link`,(data))
}

export function resetPassword(data){
    return httpservice.post(`${API}admin/reset`,(data))
}

export function resetApi(data){
    return httpservice.post(`${API}admin/reset`,(data))
}

export function sendApi(data){
    return httpservice.post(`${API}`,(data))
}

export function dashboard(){
    return httpservice.get(`${API}admin/dashboard`)
}

