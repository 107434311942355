import React, { useState } from 'react'
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { loginAPi, resetPassword } from '../../services/admin'
function Login() {
    const [newpassword, setNewpassword] = useState('')
    const [password, setPassword] = useState('')
    const navigate = useNavigate();

    const { id } = useParams();

    const handleSubmitLogin = async () => {
        let data = {
            id : id,
            password: newpassword,
            confirm_password: password
        }

        var res = await resetPassword(data);

        if (res.data.status == 200) {
            toast.success(res.data.message)
            navigate("/login")
        } else {
            toast.error(res.data.message)
        }

    }
    return (
        <>
            <div className='login'>
                <div className='login_box container col-4 shadow-lg'>
                    <h3 className='text-center text-pink my-4'> Reset Password </h3>
                    <div className=''>
                        <input type="password" required placeholder='Password' onChange={(e) => setNewpassword(e.target.value)} className='form-control' />
                        <br />
                        <input type="password" required placeholder='Confirm Password' onChange={(e) => setPassword(e.target.value)} className='form-control' />
                        <br />
                        <button type='sumbit' onClick={handleSubmitLogin} className='login_button btnsetEmail bg-pink w-100'>Submit</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login