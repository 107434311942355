import React, { Component, useEffect, useState } from 'react'
import { dashboard } from '../services/admin'

// export default class Body extends Component {
function Body() {
  // render() {
    const [getCardData, setCardData] = useState({})
    const dashboardData = async () => {
      let res = await dashboard();
      if (res.data.status == 200) {
        setCardData(res.data.data.cardData)
      }
    }

    useEffect(() => {
      dashboardData()
    }, [])
    return (
      <div> <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h6 className="m-0">Dashboard</h6>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className='card card-body'>
              <div className="row">
                <div className="col-lg-12 col-6">
                  <div className="small-box bg-success">
                    <div className="inner">
                      <h3>{getCardData ? getCardData.subUser : 0}</h3>
                      <p>Subscribed Users</p>
                    </div>
                    <div className="icon">
                      <i className="ion ion-ios-people" />
                    </div>
                    {/* <a className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a> */}
                  </div>
                </div>
                <div className="col-lg-12 col-6">
                  <div className="small-box bg-warning">
                    <div className="inner">
                      <h3>{getCardData ? getCardData.totalUser : 0}</h3>
                      <p>Total Users</p>
                    </div>
                    <div className="icon">
                      <i className="ion ion-person-add" />
                    </div>
                    {/* <a className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a> */}
                  </div>
                </div>
                <div className="col-lg-12 col-6">
                  <div className="small-box bg-danger">
                    <div className="inner">
                      <h3>{getCardData ? getCardData.unSubUser : 0}</h3>
                      <p>Unsubscribed Users</p>
                    </div>
                    <div className="icon">
                      <i className="ion ion-android-walk" />
                    </div>
                    {/* <a className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a> */}
                  </div>
                </div>
              </div>
            </div>
          </div></section></div></div>

    )
  // }
}
export default Body
