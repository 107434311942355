import httpservice from "./httpservice";
import { API } from "../backend";

export function getReasonList(data){
    return  httpservice.get(`${API}admin/reportsList?page=${data.page}`)
}

export function userReported(page){
    return  httpservice.get(`${API}admin/userReported?page=${page}`)
}
export function statusChange(data){
    return httpservice.post(`${API}admin/reports`,(data))
}
export function addReason(data){
    return httpservice.post(`${API}admin/reports`,(data))
}
export function deleteReason(data){
    return httpservice.post(`${API}admin/delete-report?id=${data.id}`)
}
