import React, { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap';
import Header from '../Header'
import Menu from '../Menu'
import { toast } from 'react-toastify';
import Pagination from "react-js-pagination";
import axios from 'axios';
import Loader from '../shared/loader'
import { API } from '../../backend';
import { notificationsList, deleteNotificationById } from '../../services/notification';
import moment from 'moment';

function NotificationList() {
   const [loader, setLoader] = useState(false)
   const [show, setShow] = useState(false);
   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);

   const [getNotification, setNotification] = useState('');
   const handleCreateSubmit = async () => {
      setLoader(true);
      var data = {
         message: getNotification
      }
      let res = await axios({
         url: `${API}admin/notification/add`,
         method: "POST",
         headers: {
            Accept: "application/json",
         },
         data: data,
      });

      if (res.data.status == 200) {
         notifications(activePage);
         handleClose();
      }
      setLoader(false);
   }

   const [activePage, setActivePage] = useState(1);
   const [total, setTotal] = useState(0);
   const [getNotifications, setNotifications] = useState([]);

   const notifications = async (page = activePage) => {
      var data = {
         page: page
      }
      var res = await notificationsList(data);

      if (res.data.status == 200) {
         setNotifications(res.data.data.notifications);
         setTotal(res.data.data.total);
      } else {
         console.log(res.data.message);
      }
   }

   const handleNotification = (e) => {
      setNotification(e.target.value)
   }

   const handlePageClick = (page) => {
      setActivePage(page)
      notifications(page)
   }

   const deleteNotification = async (id) => {
      setLoader(true);
      var res = await deleteNotificationById(id);

      if (res.data.status == 200) {
         setLoader(false);
         notifications(activePage)
      } else {
         setLoader(false);
         alert('Something went wrong')
      }
   }

   var count = ((activePage - 1) * 10 + 1);
   useEffect(() => {
      notifications()
   }, [])
   return (
      <>
         <div>
            {loader === true ? <Loader /> : null}
         </div>
         <Header />
         <Menu />
         <div className='content-wrapper'>
            <div class="breadcrum">
               <span>Notification Management</span>
            </div>
            <div class="container">
               <div className='button_wrap'>
                  <button class='btn btn-primary btn-sm' onClick={handleShow}>Add Notification</button>
               </div>
            </div>
            <div className='card card-body'>
               <table className='table table-hover'>
                  <thead>
                     <tr>
                        <th scope="col">S.no</th>
                        <th scope="col">Notification</th>
                        <th scope="col">posted Date</th>
                        <th scope="col">Action</th>
                     </tr>
                  </thead>
                  <tbody>
                     {getNotifications && getNotifications.length > 0 ? getNotifications.map((value, i) => (
                        <tr>
                           <td>{count++}</td>
                           <td>{value.message}</td>
                           <td>{moment(value.createdAt).format('YYYY/M/D')}</td>
                           <td><Button onClick={() => deleteNotification(value._id)}><i class="fas fa-trash"></i></Button></td>

                        </tr>)) : "No data found"}
                  </tbody>
                  <div className="Pagination">
                     <Pagination
                        activePage={activePage}
                        previousLabel={"previous"}
                        nextLabel={"next"}
                        itemsCountPerPage={10}
                        totalItemsCount={total}
                        pageRangeDisplayed={10}
                        onChange={handlePageClick}
                     />
                  </div>
               </table>
            </div>

            <Modal
               show={show}
               onHide={handleClose}
               backdrop="static"
               keyboard={false}
            >
               <Modal.Header closeButton>
                  <Modal.Title>Add Notification</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  <form>
                     <textarea maxLength={500} onChange={(e) => handleNotification(e)} cols="30" rows="10"></textarea>
                     {/* <input type="text" onChange={(e) => handleNotification(e)} /> */}
                  </form>
               </Modal.Body>
               <Modal.Footer>

                  <Button variant="primary" onClick={handleCreateSubmit}>Submit</Button>
               </Modal.Footer>
            </Modal>
         </div>

      </>
   )
}

export default NotificationList