import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { loginAPi } from '../../services/admin'
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()

function Login() {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const navigate = useNavigate();
    const notify = () => {
        // toast("Login Successfully")
    }

    const handleSubmitLogin = async () => {
        let data = {
            email: email,
            password: password
        }
        
        let result = await loginAPi({ ...data });

        console.log(result.data.status);
       
        if (result.data.status == 200) {
            let loginToken = result.data.data.token;
            window.localStorage.setItem("logintoken", loginToken);
            navigate('/')

        } else {

            toast("invalid user name or password")

            console.log("invalid user name and password")

        }
    }
    return (
        <>
            <div className='login'>
                <div className='login_box container col-4 shadow-lg'>
                    <h3 className='text-center text-pink my-4'>BotshLove login </h3>
                    <div className=''>
                        <input type="text" placeholder='email' onChange={(e) => setEmail(e.target.value)} className='form-control' />
                        <br />
                        <input type="password" placeholder='password' onChange={(e) => setPassword(e.target.value)} className='form-control' />
                        <br />
                        <button type='sumbit' onClick={() => {
                            handleSubmitLogin()
                            notify()
                        }} className='login_button btn bg-pink w-100'>Login</button>
                        <p className='forgot-passowrd text-right '>
                            <NavLink to="/forgotpassword" style={{ textDecoration: 'none' }}>Forgot Password ?</NavLink>
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login