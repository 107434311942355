import axios from 'axios'
import React from 'react'
import { useState, useEffect } from 'react'
import { Navigate, useParams, useNavigate } from 'react-router-dom'
import { API } from '../../backend'
import { getSubscriptionDetail } from '../../services/subsciption'
import Header from '../Header'
import Menu from '../Menu'
import Loader from '../shared/loader'
function SubscriptionEdit() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [loader, setLoader] = useState(true)
    const [activePage, setActivePage] = useState(1);
    const [getSubscriptionsDetail, setSubscriptionsDetail] = useState({});
    const subsciptionDetail = async () => {

        var data = {
            id: id
        };

        setLoader(true);

        var res = await getSubscriptionDetail(data);
        if (res.data.status == 200) {
            setSubscriptionsDetail(res.data.data.subscription);
            setName(res.data.data.subscription.plan_name);
            setDuration(res.data.data.subscription.plan_duration);
            setAmount(res.data.data.subscription.plan_amount);
        }
        setLoader(false);
    }

    const handleSubmit = async () => {
        var data = {
            id: id,
            plan_name: getName,
            plan_duration: getDuration,
            plan_amount: getAmount,
        }
        setLoader(true);
        let res = await axios({
            url: `${API}admin/subscription/update`,
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            data: data,
        });

        if (res.data.status == 200) {
            alert('Success');
            navigate('/subscription/list');
        }
        setLoader(false);
    }

    const [getName, setName] = useState('');
    const handleNameChange = (e) => {
        if (e.target.value.length > 20) {
            return;
        }
        setName(e.target.value)
    }

    const [getDuration, setDuration] = useState('');
    const handleDurationChange = (e) => {
        // if (e.target.value <= 12) {
        if (e.target.value.length > 2) {
            return;
        }
        setDuration(e.target.value)
        // } else {
        //     return false;
        // }
    }

    const [getAmount, setAmount] = useState('');
    const handleAmountChange = (e) => {
        if (e.target.value.length > 2) {
            return;
        }
        setAmount(e.target.value)
    }

    useEffect(() => {
        subsciptionDetail();
    }, [])

    var count = ((activePage - 1) * 10 + 1);

    return (
        <>
            <div>
                {loader === true ? <Loader /> : null}
            </div>
            <Header />
            <Menu />
            <div className='content-wrapper'>
                <div className="breadcrum">
                    <span>Subscription Edit</span>
                </div>

                <form>
                    <div className='form_content'>
                        <div className='form_field_wrap'>
                            <div className='form_field'>
                                <label>Name</label>
                                <input className='input' type="text" value={getName} onChange={handleNameChange} />
                            </div>
                            <div className='form_field'>
                                <label>Duration (In month) </label>
                                <input className='input' type="number" value={getDuration} onChange={handleDurationChange} />
                            </div>
                        </div>
                        <div className='form_field_wrap'>
                            <div className='form_field'>
                                <label>Amount</label>
                                <input className='input' type="number" value={getAmount} onChange={handleAmountChange} />
                            </div>
                            <div className='form_field'>

                            </div>
                        </div>
                        <div className='button_wrap'>
                            <button type='button' onClick={handleSubmit}>Submit</button>
                        </div>
                    </div>
                </form>

            </div>
        </>
    )
}

export default SubscriptionEdit