import httpservice from "./httpservice";
import {
    API
} from "../backend";

export function getQualificationList(page) {
    return httpservice.get(`${API}admin/qualification?page=${page}`)
}

export function addQualification(data) {
    return httpservice.post(`${API}admin/qualification`, (data))
}

export function statusChange(data) {
    return httpservice.post(`${API}admin/qualification`, (data))
}


export function deleteRow(data){
    return httpservice.get(`${API}admin/deleteQualification?id=${data.id}`)
}