import httpservice from "./httpservice";
import { API } from "../backend";
httpservice.setJwt(getJwt());

export function getJwt(){
    return localStorage.getItem('logintoken');
}
export function getCountryList(page){
    return httpservice.get(`${API}admin/country?page=${page}`)
}

export function addCountry(data){
    return httpservice.post(`${API}admin/country`,(data))
}

export function statusChange(data){
    return httpservice.post(`${API}admin/country`,(data))
}

export function deleteCountry(data){
    return httpservice.get(`${API}admin/deleteCountry?id=${data.id}`)
}