import React from 'react'
import ClipLoader from "react-spinners/ClipLoader"; 
function loader() {
    // const [loading, setLoading] = useState(false);
    // useEffect(()=>{
    //     setLoading(true)
    //     setTimeout(()=>{
    //        setLoading(false)
    //     }, 8000)

    // },  [])
    return (
        <div className='app loader'>                
                <ClipLoader loading  />
        </div>
    )
}

export default loader