import httpservice from "./httpservice";
import { API } from "../backend";

export function getRelationList(page){
    return  httpservice.get(`${API}admin/relationship?page=${page}`)
}

export function addRelation(data){
    return httpservice.post(`${API}admin/relationship`,(data))
}

export function statusChange(data){
    return httpservice.post(`${API}admin/relationship`,(data))
}

export function deleteRow(data){
    return httpservice.get(`${API}admin/deleteRelationship?id=${data.id}`)
}