import httpservice from "./httpservice";
import { API } from "../backend";
httpservice.setJwt(getJwt());

export function getJwt(){
    return localStorage.getItem('logintoken');
}

export function getCmsDetails(type='privacy'){
    return httpservice.get(`${API}user/get-cms?type=${type}`)
}
