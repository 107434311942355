import React from 'react'
import Header from '../Header'
import Menu from '../Menu'
import Body from '../Body'
function Dashboard() {
    return (
        <>
            <Header />
            <Menu />
            <Body />
        </>
    )
}

export default Dashboard