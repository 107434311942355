import React, { useEffect, useState } from 'react'
import { getUserList, changeUserStatus } from '../../services/user'
import ReactPaginate from 'react-paginate';
import Loader from '../shared/loader'
import Header from '../Header'
import Menu from '../Menu'
import Pagination from "react-js-pagination";
import { NavLink,Link } from 'react-router-dom';
import moment from 'moment';

function UserList() {

    const [user, setUser] = useState([]);
    const [item] = useState([]);
    const [loader, setLoader] = useState(true)
    let pageOffSet = {
        page: 1,
        limit: 8
    }

    useEffect(() => {
        userList()
    }, [])
    const [activePage, setActivePage] = useState(1);
    const [total, setUserTotal] = useState(0);
    const userList = async (page = activePage, search= getSeachInput, gender = getGenderSelect, date = getDateSelect, toDate = getToDateSelect) => {
        try {            
            setLoader(true)
            var data = {
                page : page,
                limit : 10,
                search : search,
                gender : gender,
                from_date : date,
                to_date : toDate,
            }
            let res = await getUserList(data);
            setLoader(false)
            const userlist = res.data.data.userlist
            setUser(userlist)
            setUserTotal(res.data.data.total)
            // console.log("user",data.userlist)
        } catch (err) {
            console.log(err);
        }
    }

    const handlePageClick = async (page) => {
        setActivePage(page);
        userList(page, getSeachInput, getGenderSelect, getDateSelect, getToDateSelect)

    }

    const [getGenderSelect, setGenderSelect] = useState('');
    const handleGenderSelect = async (e) => {
        setGenderSelect(e.target.value);
        userList(activePage, getSeachInput, e.target.value, getDateSelect, getToDateSelect)

    }

    const [getDateSelect, setDateSelect] = useState('');
    const handleDateSelect = async (e) => {
        setDateSelect(e.target.value);
        userList(activePage, getSeachInput,getGenderSelect, e.target.value, getToDateSelect)

    }

    const [getToDateSelect, setToDateSelect] = useState('');
    const handleToDateSelect = async (e) => {
        setToDateSelect(e.target.value);
        userList(activePage, getSeachInput,getGenderSelect, getDateSelect, e.target.value)

    }
    const [getSeachInput, setSeachInput] = useState('');
    const handleFilter = (e) => {
        setSeachInput(e.target.value)
        userList(activePage, e.target.value, getGenderSelect, getDateSelect, getToDateSelect)
    }

    const handleChangeStatus = async (data) => {
        var data = {
            id: data
        };

        // setLoader(true);
        var res = await changeUserStatus(data);
        if (res.data.status == 200) {
            userList(activePage, getSeachInput, getGenderSelect, getDateSelect, getToDateSelect)
        }
        // setLoader(false);
    }

    var count = ((activePage - 1) * 10 + 1);

    return (
        <>
            <div>
                {loader === true ? <Loader /> : null}
            </div>
            <Header />
            <Menu />
            <div className='content-wrapper'>
                <div className='breadcrum'>
                    <span>User Management</span>
                </div>
                <div className="search-container">
                    {/* <form action="/action_page.php"> */}
                        <input className='searchBox space input text-pink' type="text" placeholder="Search.." onChange={(e) => handleFilter(e)} name="search" />
                        {/* <button type="submit"><i className="fa fa-search" /></button> */}
                        <div class='gender space'>
                            <select className="gender input btn btn-info" onChange={(e) => handleGenderSelect(e)}>
                                <option value="">Gender</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                            </select>
                        </div>
                        <div class='date space'>
                           <input className='datepick input' onChange={(e) => handleDateSelect(e)} type="date" />
                        </div>
                        <div class='space'>
                           <input className='datepick input' onChange={(e) => handleToDateSelect(e)} type="date" />
                        </div>
                        <div className='space'>
                            <select className="reports input btn btn-danger">
                                <option value="none" selected>Reports</option>
                                <option value="Increasing">Increasing</option>
                                <option value="Decreasing">Decreasing</option>
                            </select>
                        </div>
                    {/* </form> */}
                </div>
                <div className='card card-body'>
                    <table className='table table-hover'>
                        <thead>
                            <tr>
                                <th scope="col">S.no</th>
                                <th scope="col">Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Mobile Number</th>
                                <th scope="col">Report</th>
                                <th scope="col">Gender</th>
                                <th scope="col">Subcription</th>
                                <th scope="col">Country</th>
                                <th scope="col">Created On</th>
                                <th scope="col">Action</th>
                                <th scope="col">Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            {user && user.length > 0 ? user.map((obj, i) => (<tr>
                                <td scope="row">{count++}</td>
                                <td>{obj.name}</td>
                                <td>{obj.email}</td>
                                <td>{obj.contactNumber}</td>
                                <td>{obj.reportedusersCount}</td>
                                <td>{obj.gender}</td>
                                <td>Subscription</td>
                                <td>{obj.country ? obj.country.countryName : ""}</td>
                                <td>{obj.registerDate}</td>
                                {/* <td>Active</td> */}
                                <td onClick={() => handleChangeStatus(obj._id)}>
                                    {
                                        obj.isActive ? (
                                            <button className='btn bg-pink'>Active</button>
                                        ) : (
                                            <button className='btn btn-outline-danger'>InActive</button>
                                        )}
                                </td>
                                <td><Link to={`/user/list/details/${obj._id}`}><button className='iconBtn'><i className="fas text-pink fa-eye"></i></button></Link></td>
                            </tr>)) : 'No data found'}
                        </tbody>
                    </table>
                    <div className="Pagination">
                     <Pagination
                        activePage={activePage}
                        previousLabel={"previous"}
                        nextLabel={"next"}
                        itemsCountPerPage={10}
                        totalItemsCount={total}
                        pageRangeDisplayed={10}
                        onChange={handlePageClick}
                     />
                  </div>
                    {/* < ReactPaginate
                        previousLabel={'<<'}
                        nextLabel={'>>'}
                        breakLabel={'...'}
                        pageCount={60}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName='pagination justify-content-center'
                        pageClassName='page-item'
                        pageLinkClassName='page-link bg-pink'
                        previousClassName='page-item'
                        previousLinkClassName='page-link'
                        nextClassName='page-item'
                        nextLinkClassName='page-link'
                        breakClassName='page-item'
                        breakLinkClassName='page-link'
                        activeClassName='active'
                    /> */}
                </div>
            </div>
        </>
    )
}

export default UserList