import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import "../node_modules/admin-lte/dist/css/adminlte.css";
import "../node_modules/admin-lte/dist/js/adminlte";
import "../src/index.css";
import "../node_modules/admin-lte/plugins/fontawesome-free/css/all.css";
import "../node_modules/admin-lte/plugins/overlayScrollbars/css/OverlayScrollbars.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
   <HashRouter>
      <App />
   </HashRouter>
);


