import React, { useEffect, useState } from 'react'
import Header from '../Header'
import Menu from '../Menu'
import { getUserDetails } from '../../services/user'
import { useParams } from 'react-router-dom';
import Loader from '../shared/loader'


function UserDetails() {
    const { id } = useParams();
    const [details, setDetails] = useState({});
    const [loader, setLoader] = useState(true)
    const [getHobby, setHobby] = useState("")
    useEffect(() => {
        userDetails(id);
    }, []);
    const userDetails = async (data) => {
        try {
            setLoader(true)
            let res = await getUserDetails(data);
            setLoader(false)
            const detail = res.data.data.user;
            let hobbyData = detail.hobby;
            console.log("hobbyData", hobbyData);
            setHobby(hobbyData.toString())
            setDetails(detail)
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <>
            <div>
                {loader === true ? <Loader /> : null}
            </div>
            <Header />
            <Menu />
            <div className='content-wrapper'>
                <div className="breadcrum">
                    <span>User Managemant /</span> <span>Details</span>
                </div>
                <div className="card card-body">
                    <div className="user_profile">
                        <div className='user_image_inner'>
                            {/* <img src='' alt='user' /> */}
                        </div>
                        <div className='user_name'>
                            <span>{details.name}, 25</span>
                        </div>
                    </div>
                    <div className='personal_details'>
                        <h3>Personal Details</h3>
                        <div className='pesonal_details_inner'>
                            <ul>
                                <li><strong>Date Of Birth</strong><span>{details.dob}</span></li>
                                <li><strong>Mobile Number</strong><span>{details.contactNumber}</span></li>
                                <li><strong>Email</strong><span>{details.email}</span></li>
                                <li><strong>Country</strong><span>{details.countryEn}</span></li>
                            </ul>
                            <ul>
                                <li><strong>Hobbies</strong><span>{getHobby}</span></li>
                                <li><strong>Smoking</strong><span>{details.smokingType}</span></li>
                                <li><strong>Body Type</strong><span>{details.bodyType}</span></li>
                                <li><strong>Current Relationship</strong><span>{details.relationshipName}</span></li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserDetails