import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { Modal, Button } from 'react-bootstrap';
import { getCountryList, addCountry, statusChange, deleteCountry } from '../../services/country'
import Loader from '../shared/loader'
import Header from '../Header'
import Menu from '../Menu';
import swal from 'sweetalert';
import Pagination from 'react-js-pagination';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'

function CountryList() {
    const [show, setShow] = useState(false);
    const [country, setCountry] = useState([]);
    const handleClose = () => setShow(false);
    const [newCountryData, setNewCountryData] = useState({
        countryCode: "",
        countryName: "",
        countryNameFr: "",
    })
    const [data, setData] = useState({})
    const [loader, setLoader] = useState(true)
    const [value, setValue] = useState(0);
    const [activePage, setActivePage] = useState(1)
    const [total, setTotal] = useState(0)
    var count = ((activePage - 1) * 10 + 1);

    const handlePageClick = (page) => {
        setActivePage(page)
        countryList(page)
    }

    // add
    const handleShow = (value) => {
        console.log('vvvvvvvvvvvvvv', value);
        setValue(value);
        console.log(value)
        setShow(true)
    }

    //edit
    const handleEditCountry = async (data, value) => {
        console.log("data", data)
        setData(data);
        setValue(value);
        setShow(true)

    }
    //Delete
    const handleDeleteCountry = async (e, a) => {
        swal({
            title: "Are you sure?",
            icon: "warning",
            buttons: true,
            dangerMode: true
        })
            .then(async (ok) => {
                if (ok) {
                    let data = {
                        id: a
                    }
                    let res = await deleteCountry(data);
                    countryList(activePage)
                    swal("Good job!", res.data.message, "success");
                }
            });

    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewCountryData((prevState) => ({ ...prevState, [name]: value }))
    }

    useEffect(() => {
        countryList()
    }, [])


    const countryList = async (page = activePage) => {
        try {
            setLoader(true)
            let response = await getCountryList(page);
            console.log("aaaaaa", response)
            const { data } = response.data;
            setLoader(false)
            setCountry(data.countriesList)
            setTotal(data.total)
            console.log("country", data.countriesList)
        } catch (err) {
            console.log(err)
        }
    }
    const handleChangeStatus = async (data) => {
        const updateObject = {
            "key": "2",
            "_id": data._id,
            "countryName": data.countryName,
            "isActive": !data.isActive
        }
        console.log("cccc", updateObject)
        let response = await statusChange(updateObject)
        if (response.status === 200) {
            const newCountryList = [...country];
            console.log("country list", newCountryList)
            const index = newCountryList.findIndex((m) => m._id === data._id)
            newCountryList[index].status = updateObject.isActive;
            setCountry(newCountryList)
            countryList()
        }
    }

    const handleSubmit = async () => {
        setValue(1);
        const { countryCode, countryName, countryNameFr } = newCountryData;
        const obj = {
            "key": "1",
            "countryCode": countryCode.trim(),
            "countryName": countryName.trim(),
            "countryNameFr": countryNameFr.trim(),
        }
        let { data } = await addCountry(obj);
        if (data.status === 201) {
            toast.success(data.message)
            handleClose()
            countryList()
        } else {
            toast.error(data.message)
        }
    }

    const handleEditSubmit = async () => {
        let obj = {
            _id: data._id,
            countryName: newCountryData.countryName.trim(),
            countryCode: newCountryData.countryCode.trim(),
            countryNameFr : newCountryData.countryNameFr.trim(),
            isActive: data.isActive,
            key: 2,
        }
        let { data: result } = await addCountry(obj);
        if (result.status == 200) {
            toast.success(result.message)
            console.log("result", result)
            console.log("updated successfully")
            handleClose()
            countryList()
        } else {
            toast.error(result.message)
        }
    }

    return (
        <>
            <div>
                {loader === true ? <Loader /> : null}
            </div>
            <Header />
            <Menu />
            <div className='content-wrapper'>
                <div className="breadcrum">
                    <span>Add Country</span>
                </div>
                <div className='content'>
                    <div className='button_wrap'>
                        <button className='btn bg-pink btn-sm' onClick={() => handleShow(1)}>Add Country</button>
                    </div>
                </div>
                <div className='card card-body'>
                    <table className='table table-hover'>
                        <thead>
                            <tr>
                                <th scope="col">S.no</th>
                                <th scope="col">Country Code</th>
                                <th scope="col">Country</th>
                                <th scope="col">Country (Fr)</th>
                                <th scope="col">Status</th>
                                <th scope="col">Edit</th>
                                <th scope="col">Delete</th>

                            </tr>
                        </thead>
                        <tbody>
                            {country && country.length > 0 ? country.map((obj, i) => (
                                <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>{obj.countryCode}</td>
                                    <td>{obj.countryName}</td>
                                    <td>{obj.countryNameFr}</td>
                                    {/* <th>{obj.isActive?"Active":"Inactive"}</th */}
                                    <td onClick={() => handleChangeStatus(obj)}>
                                        {
                                            obj.isActive ? (
                                                <button className='btn bg-pink'>Active</button>


                                            ) : (
                                                <button className='btn btn-outline-danger'>InActive</button>


                                            )}
                                    </td>
                                    <td><button className='iconBtn' onClick={() => handleEditCountry(obj, 2)}><i class="fas text-pink fa-edit"></i></button></td>
                                    <td><button className='iconBtn' onClick={(e) => handleDeleteCountry(e, obj._id)}><i class="fas text-pink fa-trash"></i></button></td>
                                </tr>
                            )) : "No data found"}
                        </tbody>



                    </table>
                    <div className="Pagination">
                        <Pagination
                            activePage={activePage}
                            previousLabel={"previous"}
                            nextLabel={"next"}
                            itemsCountPerPage={10}
                            totalItemsCount={total}
                            pageRangeDisplayed={10}
                            onChange={handlePageClick}
                        />
                    </div>
                </div>

                {
                    value == 1 ?
                        <Modal
                            show={show}
                            onHide={handleClose}
                            backdrop="static"
                            keyboard={false}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Add Country</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <form>
                                    <input className='input' maxLength={5} type="text" name="countryCode" onChange={e => { handleChange(e) }} placeholder='Enter Country Code' />
                                    <input className='input' maxLength={30} type="text" name="countryName" placeholder='Enter Country (En)' onChange={e => { handleChange(e) }} />
                                    <input className='input' maxLength={30} type="text" name="countryNameFr" placeholder='Enter Country (Fr)' onChange={e => { handleChange(e) }} />

                                </form>
                            </Modal.Body>
                            <Modal.Footer>
                                
                                <Button className='btn bg-pink' onClick={handleSubmit}>Submit</Button>
                            </Modal.Footer>
                        </Modal> :
                        <Modal
                            show={show}
                            onHide={handleClose}
                            backdrop="static"
                            keyboard={false}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Edit Country</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <form>
                                    <input className='input' maxLength={5} defaultValue={data.countryCode} type="text" name="countryCode" onChange={e => { handleChange(e) }} placeholder='Enter Country Code' />
                                    <input className='input' maxLength={30} defaultValue={data.countryName} type="text" name="countryName" placeholder='Enter Country (En)' onChange={e => { handleChange(e) }} />
                                    <input className='input' maxLength={30} defaultValue={data.countryNameFr} type="text" name="countryNameFr" placeholder='Enter Country (Fr)' onChange={e => { handleChange(e) }} />

                                </form>
                            </Modal.Body>
                            <Modal.Footer>

                                <Button className='btn bg-pink' onClick={handleEditSubmit}>Submit</Button>
                            </Modal.Footer>
                        </Modal>
                }
            </div>
        </>
    )

}

export default CountryList