import httpservice from "./httpservice";
import { API } from "../backend";

httpservice.setJwt(getJwt());

export function getJwt(){
    return localStorage.getItem('logintoken');
}

export function userQueriesList(data, type=""){
    return httpservice.get(`${API}admin/user-queries/list?page=${data.page}&search=${data.search}&gender=${data.gender}&date=${data.date}&toDate=${data.toDate}&type=${type}`)
}

export function resolveQueryList(data) {
    return httpservice.get(`${API}admin/user-queries/resolve-queries?id=${data.id}`);
}