import axios from "axios";
import { toast } from "react-toastify";

axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;
  if (!expectedError) {
    toast.error("An unexpected error occurred");
  }
  return Promise.reject(error);
});
function setJwt() {
  // console.log("token", localStorage.getItem('logintoken'));
  let jwt = localStorage.getItem('logintoken');
  if (jwt != null) axios.defaults.headers.common["Authorization"] = jwt;

  return;
}

// export const setNewJWT=()=>{
//   const token=localStorage.getItem('logintoken')
//   return headers: {
//     'Authorization': token
//   }
// }

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  patch: axios.patch,
  delete: axios.delete,
  setJwt,
};