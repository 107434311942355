import httpservice from "./httpservice";
import { API } from "../backend";

export function getSmokeList(page){
    return httpservice.get(`${API}admin/smoking?page=${page}`)
}

export function statusChange(data){
    return httpservice.post(`${API}admin/smoking`,(data))
}

export function addSmoke(data){
    return httpservice.post(`${API}admin/smoking`,(data))
}

export function deleteRow(data){
    return httpservice.get(`${API}admin/deleteSmoking?id=${data.id}`)
}
