import './App.css';
import { Route, Routes, Navigate } from 'react-router-dom'
import HobbiesList from './component/HobbiesModule/HobbiesList';
import CountryList from './component/CountryModules/CountryList';
import UserList from './component/UserModules/UserList';
import QueryList from './component/QueriesModules/QueryList';
import SolvedQueryList from './component/QueriesModules/SolvedQueryList';
import SubscriptionList from './component/SubscriptionModule/SubscriptionList';
import ReasonList from './component/ReasonModules/ReasonList';
import NotificationList from './component/NotificationModule/NotificationList';
import Dashboard from './component/Dashboard/Dashboard';
import Login from './component/LoginModule/Login';
import Reset from './component/ResetModule/Reset';
import ForgotPass from './component/ForgotModule/ForgotPass'
import UserDetails from './component/UserModules/UserDetails';
import BodyList from './component/BodyModule/BodyList';
import SmokingList from './component/SmokingModule/SmokingList';
import RealtionshipList from './component/RelationshipModule/RealtionshipList';
import QualificationList from './component/QualificationModule/QualificationList';
import SubscriptionEdit from './component/SubscriptionModule/EditSubscription';
import ReportList from './component/UserReports/index';
import TermsCondition from './staticPages/TermsCondition';
import PrivacyPolicy from './staticPages/privacyPolicy';
function App() {
   const ProtectedRoute = ({ children }) => {
      if (!localStorage.getItem("logintoken")) {
        return <Navigate to="/login" replace />;
      }
      return children;
    };
   return (
      <>
         <Routes>
            <Route path='/login' element={<Login />} />
            <Route path='/termscondition' element={<TermsCondition />} />
            <Route path='/privacypolicy' element={<PrivacyPolicy />} />
            <Route path='/' element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
            <Route path='/list' element={<ProtectedRoute><HobbiesList /></ProtectedRoute>} />
            <Route path='/country/list' element={<ProtectedRoute><CountryList /></ProtectedRoute>} />
            <Route path='/user-reported' element={<ProtectedRoute><ReportList /></ProtectedRoute>} />
            <Route path='/user/list' element={<ProtectedRoute><UserList /></ProtectedRoute>} />
            <Route path='user/list/details/:id' element={<ProtectedRoute><UserDetails /></ProtectedRoute>} />
            <Route path='/query/list' element={<ProtectedRoute><QueryList /></ProtectedRoute>} />
            <Route path='query/list/resolve' element={<ProtectedRoute><SolvedQueryList /></ProtectedRoute>} />
            <Route path='/subscription/list' element={<ProtectedRoute><SubscriptionList /></ProtectedRoute>} />
            <Route path='/subscription/edit/:id' element={<ProtectedRoute><SubscriptionEdit /></ProtectedRoute>} />
            <Route path='/reason/list' element={<ProtectedRoute><ReasonList /></ProtectedRoute>} />
            <Route path='/notification/list' element={<ProtectedRoute><NotificationList /></ProtectedRoute>} />
            <Route path='/forgotpassword' element={<Reset />} />
            <Route path='/resetpass' element={<ProtectedRoute><ForgotPass /></ProtectedRoute>} />
            <Route path='/reset-password/:id' element={<ForgotPass />} />
            <Route path='/bodyTypelist' element={<ProtectedRoute><BodyList /></ProtectedRoute>} />
            <Route path='/smokinglist' element={<ProtectedRoute><SmokingList /></ProtectedRoute>} />
            <Route path='/relationstatus' element={<ProtectedRoute><RealtionshipList /></ProtectedRoute>} />
            <Route path='/qualification' element={<ProtectedRoute><QualificationList/></ProtectedRoute>}/>
         </Routes>
      </>


   );
}

export default App;
