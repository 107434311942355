import React, { useEffect, useState } from 'react'
import { Modal, Button } from 'react-bootstrap';
import { getReasonList, statusChange, addReason, deleteReason, userReported } from '../../services/reason'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../shared/loader'
import Pagination from "react-js-pagination";
import Header from '../Header'
import Menu from '../Menu'
import swal from 'sweetalert';
import moment from 'moment';
toast.configure()
function ReportList() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [loader, setLoader] = useState(true)
    const [reason, setReason] = useState([]);
    const [newReason, setReasons] = useState("")
    const [activePage, setActivePage] = useState(1)
    const [total, setTotal] = useState(0)
    var count = ((activePage - 1) * 10 + 1);
    const notify = () => {
        toast("Reason Added Successfully")
    }

    useEffect(() => {
        reasonList()
    }, [])

    const handlePageClick = (page) => {
        setActivePage(page)
        reasonList(page)
     }
    const reasonList = async (page = activePage) => {
        try {
            setLoader(true)
            let res = await userReported(page);
            console.log(res.data.data.reports);
            setLoader(false)
            const reports = res.data.data.reports;
            console.log("reports array", reports)
            // console.log("reason", reports);
            setReason(reports)
            setTotal(res.data.data.total)
        } catch (err) {
            console.log(err);
        }
    }

    const handleChangeStatus = async (data) => {
        console.log("data", data)
        const { _id, reportName, isActive } = data
        const updateObject = {
            "key": "2",
            "_id": _id,
            "reportName": reportName,
            "isActive": !isActive
        }
        console.log("hehe", updateObject)
        let response = await statusChange({ ...updateObject })
        console.log("ressponse", response)

        if (response.status == 200) {
            const newReasonList = [...reason]
            // console.log("aaa",newReasonList);
            const index = newReasonList.findIndex((m) => m._id === data._id)
            newReasonList[index].status = updateObject.isActive;
            setReason(newReasonList)
            reasonList()
        }
    }
    
    const handleDeleteReason = async (e, id) => {
        swal({
            title: "Are you sure?",
            icon: "warning",
            buttons: true,
            dangerMode: true
          })
            .then(async (ok) => {
              if (ok) {
                let res = await deleteReason({ id: id });
                if (res.data.status == 200) {
                  swal("Good job!", res.data.message, "success");
                  reasonList()
                }
              }
            });
    }

    const handleSubmit = async () => {
        console.log("lalala", newReason)
        const obj = {
            "key": "1",
            "reportName": newReason,
            "isActive": true
        }
        let { data } = await addReason(obj);
        // notify()
        if (data.status == 201) {
            handleClose(handleSubmit)
            reasonList()
        }

    }
    return (
        <>
            <div>
                {loader == true ? <Loader /> : null}
            </div>
            <Header />
            <Menu />
            <div className='content-wrapper'>
                <div className="breadcrum">
                    <span>Report Management</span>
                </div>
                {/* <div className='container'>
                    <div className='button_wrap'>
                        <button className='btn bg-pink btn-sm' onClick={handleShow}>Add Reason</button>
                    </div>
                </div> */}
                <div className='card card-body'>
                    <table className='table table-hover'>
                        <thead>
                            <tr>
                                <th scope='col'>S.no</th>
                                <th scope='col'>Reported To</th>
                                <th scope='col'>Reported By</th>
                                <th scope='col'>Message</th>
                                <th scope='col'>Reported at</th>
                            </tr>
                        </thead>
                        <tbody>
                            {reason && reason.length > 0 ? reason.map((obj, i) => (<tr>
                                <td scope="row">{count++}</td>
                                <td>{obj.reportedTo ? obj.reportedTo.name : ""}</td>
                                <td>{obj.reportedBy ? obj.reportedBy.name : ""}</td>
                                <td>{obj.message ? obj.message.toString() : ""}</td>
                                <td>{obj.createdAt ? moment(obj.createdAt).format('YYYY-MM-DD hh:mm:ss') : ""}</td>
                                {/* <td onClick={() => handleChangeStatus(obj)}>
                                    {
                                        obj.isActive ? (
                                            <button className='btn bg-pink'>Active</button>
                                        ) : (
                                            <button className='btn btn-outline-danger'>InActive</button>
                                        )}
                                </td>
                                <td><button className='iconBtn' onClick={(e) => handleDeleteReason(e, obj._id)}><i class="fas text-pink fa-trash"></i></button></td> */}
                                {/* <td><button className='iconBtn' onClick={handleShow}><i class="fas text-pink fa-edit"></i></button></td> */}
                            </tr>)) : "No data found"}

                        </tbody>
                    </table>
                    <Pagination
                        activePage={activePage}
                        previousLabel={"previous"}
                        nextLabel={"next"}
                        itemsCountPerPage={10}
                        totalItemsCount={total}
                        pageRangeDisplayed={10}
                        onChange={handlePageClick}
                     />
                </div>

                <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Add Reason</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form>
                            <input className='input' type="text" onChange={e => { setReasons(e.target.value) }} />
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn bg-pink" onClick={() => {
                            handleSubmit()
                            
                        }}>Submit</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    )
}

export default ReportList