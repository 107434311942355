import React, { useState, useEffect } from 'react'
import { getRelationList, addRelation, statusChange, deleteRow } from '../../services/relation'
import { toast } from 'react-toastify';
import { Modal, Button } from 'react-bootstrap';
import Loader from '../shared/loader'
import Pagination from "react-js-pagination";
import Header from '../Header'
import Menu from '../Menu'
import swal from 'sweetalert';
function RealtionshipList() {
    const [relation, setRelation] = useState([])
    const [newRelation, setRelatiionship] = useState("")
    const [newRelationFr, setRelatiionshipFr] = useState("")
    const [value, setValue] = useState(0);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [updateRelation, setUpdateRelation] = useState({})
    const [loader, setLoader] = useState(true)
    const [activePage, setActivePage] = useState(1)
    const [total, setTotal] = useState(0)
    var count = ((activePage - 1) * 10 + 1);
    // add
    const handleShow = (value) => {
        // console.log('vvvvvvvvvvvvvv', value);
        setValue(value)
        setRelatiionship("");
        console.log(value)
        setShow(true)
    }

    //edit 
    const handleEditRelation = async (data, value) => {
        const { relationshipName, relationshipNameFr } = data
        setUpdateRelation(data)
        setRelatiionship(relationshipName)
        setRelatiionshipFr(relationshipNameFr)
        if (data) {
            setValue(value);
            setShow(true)
        }

    }

    useEffect(() => {
        relationshiplist()
    }, [])

    const handleDelete = async (id) => {        

        swal({
            title: "Are you sure?",
            icon: "warning",
            buttons: true,
            dangerMode: true
         })
            .then(async (ok) => {
               if (ok) {

                  let data = {
                     id: id
                  }
                  let totalCurrentData = relation.length;
                  let res = await deleteRow(data);

                  let newActivePage = activePage;
                  if ((totalCurrentData == 1) && (activePage != 1)) {
                     newActivePage = newActivePage - 1
                  }
                  setActivePage(newActivePage);
                  relationshiplist(newActivePage)
                  swal("Good job!", res.data.message, "success");
               }
            });
     }

    const handlePageClick = (page) => {
        setActivePage(page)
        relationshiplist(page)
     }
    const relationshiplist = async (page = activePage) => {
        try {
            setLoader(true)
            let res = await getRelationList(page);
            console.log(res);
            const rtype = res.data.data.relationshiplists
            setLoader(false)
            setRelation(rtype);
            setTotal(res.data.data.total)
        } catch (error) {
            console.log(error);
        }
    }

    const handleSubmit = async () => {
        console.log("reee", newRelation)
        const obj = {
            "key": "1",
            "relationshipName": newRelation.trim(),
            "relationshipNameFr": newRelationFr.trim(),
            "isActive": true
        }
        if (newRelation == "") {
            toast.error("Input field is required.")
        }
        let { data } = await addRelation(obj);
        if (data.status === 201) {
            toast.success(data.message)
            handleClose()
            relationshiplist()

        } else {
            toast.error(data.message)
        }
    }

    const handleChangeStatus = async (data) => {
        console.log("data", data);
        const { _id, isActive } = data
        const updateObject = {
            "key": "2",
            "_id": _id,
            "isActive": !isActive
        }
        // console.log(updateObject);
        let response = await statusChange({ ...updateObject })
        console.log("res", response);

        if (response.status === 200) {
            const newRelationList = [...relation]
            console.log("new relation", newRelationList);
            const index = newRelationList.findIndex((m) => m._id === data._id)
            newRelationList[index].status = updateObject.isActive;
            setRelation(newRelationList)
            relationshiplist()
        }
    }
    const handleEditSubmit = async () => {
        let { _id, isActive } = updateRelation
        let data = {
            _id,
            relationshipName: newRelation.trim(),
            relationshipNameFr: newRelationFr.trim(),
            isActive,
            key: 2
        }
        let { data: result } = await addRelation(data);
        if (result.status === 200) {
            toast.success(result.message);
            handleClose()
            relationshiplist()
        } else {
            toast.error(result.message)
        }
    }
    return (
        <>
            <div>
                {loader == true ? <Loader /> : null}
            </div>
            <Header />
            <Menu />
            <div className='content-wrapper'>
                <div className='breadcrum'>
                    <span>Relationship Listing</span>
                </div>
                <div className='container'>
                    <div className='button_wrap'>
                        <button className='btn bg-pink btn-sm' onClick={() => handleShow(1)}>Add Relation</button>
                    </div>
                </div>
                <div className='card card-body'>
                    <table className='table table-hover'>
                        <thead>
                            <tr>
                                <th>S.no</th>
                                <th>RelationType (En)</th>
                                <th>RelationType (Fr)</th>
                                <th>Status</th>
                                <th>Edit</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {relation && relation.length > 0 ? relation.map((obj, i) => (
                                <tr>
                                    <td>{i + 1}</td>
                                    <td>{obj.relationshipName}</td>
                                    <td>{obj.relationshipNameFr}</td>
                                    <td onClick={() => handleChangeStatus(obj)}>
                                        {
                                            obj.isActive ? (
                                                <button className='btn bg-pink'>Active</button>
                                            ) : (
                                                <button className='btn btn-outline-danger'>InActive</button>
                                            )}
                                    </td>
                                    <td><button className='iconBtn' onClick={() => handleEditRelation(obj, 2)}><i class="fas text-pink fa-edit"></i></button></td>
                                    <td><button className='iconBtn' onClick={() => handleDelete(obj._id)}><i class="fas text-pink fa-trash"></i></button></td>
                                </tr>
                            )) : "No data found"}
                        </tbody>
                    </table>
                    <div className="Pagination">
                     <Pagination
                        activePage={activePage}
                        previousLabel={"previous"}
                        nextLabel={"next"}
                        itemsCountPerPage={10}
                        totalItemsCount={total}
                        pageRangeDisplayed={10}
                        onChange={handlePageClick}
                     />
                  </div>
                </div>
                {
                    value == 1 ?
                        < Modal
                            show={show}
                            onHide={handleClose}
                            backdrop="static"
                            keyboard={false}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Add Relation</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <form>
                                    <input className='input' maxLength={30} placeholder='Relation Type (En)' type="text" onChange={e => { setRelatiionship(e.target.value) }} />
                                    <input className='input' maxLength={30} placeholder='Relation Type (Fr)' type="text" onChange={e => { setRelatiionshipFr(e.target.value) }} />
                                </form>
                            </Modal.Body>
                            <Modal.Footer>

                                <Button className='btn bg-pink' onClick={handleSubmit}>Submit</Button>
                            </Modal.Footer>
                        </Modal> :
                        < Modal
                            show={show}
                            onHide={handleClose}
                            backdrop="static"
                            keyboard={false}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Edit Relation</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <form>
                                    <input className='input' maxLength={30} defaultValue={newRelation} placeholder='Relation Type (En)' type="text" onChange={e => { setRelatiionship(e.target.value) }} />
                                    <input className='input' maxLength={30} defaultValue={newRelationFr} placeholder='Relation Type (Fr)' type="text" onChange={e => { setRelatiionshipFr(e.target.value) }} />
                                </form>
                            </Modal.Body>
                            <Modal.Footer>

                                <Button className='btn bg-pink' onClick={handleEditSubmit}>Submit</Button>
                            </Modal.Footer>
                        </Modal>
                }
            </div>
        </>
    )
}

export default RealtionshipList